import { useGetConformanceFlowsQuery } from '../../../app/apiSlice'
import { useGetProcessConformanceMetricsQuery } from '../../../app/apiSliceProcessApi'
import { Stats } from '../../../common/components'
import { useQueryFilters } from '../../../common/hooks'
import { formatNumber, getBestTimeFormatForSeconds } from '../../../common/utils/numberFormatting'
import { ConformanceTypeEnum } from '../types'

const OverviewHarmonization = () => {
  const { processId, processFilters: filters } = useQueryFilters()

  const { data: conformanceFlowList, isFetching: isFetchingConformanceFlowList } =
    useGetConformanceFlowsQuery({
      processId,
      conformanceType: ConformanceTypeEnum.STEP_FLOW,
    })

  const { data, isFetching } = useGetProcessConformanceMetricsQuery(
    {
      processId,
      filters,
      conformance_flows: (conformanceFlowList ?? []).map((c) => c.steps),
      conformance_type: ConformanceTypeEnum.STEP_FLOW,
    },
    { skip: isFetchingConformanceFlowList },
  )

  const {
    throughput_diff_to_others_sec = 0,
    annualized_touch_count_diff = 0,
    annualized_duration_diff_sec = 0,
  } = data ?? {}

  const harmonizationMetrics = [
    {
      label: 'Improved duration',
      value: `${getBestTimeFormatForSeconds(throughput_diff_to_others_sec)}`,
    },
    {
      label: 'Saved annual work time',
      value: `${getBestTimeFormatForSeconds(annualized_duration_diff_sec)}`,
    },
    {
      label: 'Reduced annual touches',
      value: `${formatNumber(annualized_touch_count_diff, 1)}`,
    },
  ]

  return (
    <Stats
      data={harmonizationMetrics}
      title='Improvement potential'
      description='Compared to the defined process best practice'
      isLoading={isFetching}
    />
  )
}

export default OverviewHarmonization
