import { ChartTypeRegistry, TooltipItem } from 'chart.js'
import { useMemo, useRef, useState } from 'react'

import { useGetTransactionTrendsQuery } from '../../../app/apiSliceProcessApi'
import { BarChartAndTableDisplay, Loader } from '../../../common/components'
import type { ChartDataEntry } from '../../../common/components/Charts/types'
import { Select, Text } from '../../../common/designs'
import { useDashboard, useQueryFilters } from '../../../common/hooks'
import {
  formatNumber,
  getBestTimeFormatTransformationForSeconds,
} from '../../../common/utils/numberFormatting'
import { type TrendData, TrendTimeGranularityEnum, TrendVariableEnum } from '../types'
import { TREND_TIME_GRANUARITY } from './TrendConstants'
import TrendFilterModal from './TrendFilterModal'
import {
  TREND_METRICS_THAT_CAN_BE_FILTERED,
  getLabelForTrend,
  weekToDateString,
} from './TrendHelpers'

const TREND_SELECT_OPTIONS = [
  { value: TrendVariableEnum.totalTransactions, label: 'Touched Cases' },
  { value: TrendVariableEnum.startedTransaction, label: 'Started Cases' },
  { value: TrendVariableEnum.closedTransactions, label: 'Closed Cases' },
  { value: TrendVariableEnum.totalDuration, label: 'Total active work time' },
  { value: TrendVariableEnum.avgDuration, label: 'Active work time per case' },
  { value: TrendVariableEnum.totalTouches, label: 'Total touches' },
  { value: TrendVariableEnum.avgTouches, label: 'Touches per case' },

  {
    value: TrendVariableEnum.endedTransactionAvgDuration,
    label: 'Avg. Duration By Completion Date',
  },
  {
    value: TrendVariableEnum.endedTransactionMedianDuration,
    label: 'Median Duration By Completion Date',
  },
  {
    value: TrendVariableEnum.endedTransactionDurationStandardDeviation,
    label: 'Duration Standard Deviation By Completion Date',
  },
  {
    value: TrendVariableEnum.startedTransactionAvgDuration,
    label: 'Avg. Duration By Start Date',
  },
  {
    value: TrendVariableEnum.startedTransactionMedianDuration,
    label: 'Median Duration By Start Date',
  },
]

const TREND_SELECT_OPTIONS_PLATFORM_VIEWER = [
  {
    value: TrendVariableEnum.endedTransactionAvgDuration,
    label: 'Avg. Duration By Completion Date',
  },
  {
    value: TrendVariableEnum.endedTransactionMedianDuration,
    label: 'Median Duration By Completion Date',
  },
  {
    value: TrendVariableEnum.endedTransactionDurationStandardDeviation,
    label: 'Duration Standard Deviation By Completion Date',
  },
  {
    value: TrendVariableEnum.startedTransactionAvgDuration,
    label: 'Avg. Duration By Start Date',
  },
  {
    value: TrendVariableEnum.startedTransactionMedianDuration,
    label: 'Median Duration By Start Date',
  },
  { value: TrendVariableEnum.totalTransactions, label: 'Touched Cases' },
  { value: TrendVariableEnum.startedTransaction, label: 'Started Cases' },
  { value: TrendVariableEnum.closedTransactions, label: 'Closed Cases' },
  { value: TrendVariableEnum.avgTouches, label: 'Touches per case' },
]

const VARIABLE_UNIT_MAPPER = {
  [TrendVariableEnum.totalTransactions]: 'Cases',
  [TrendVariableEnum.startedTransaction]: 'Cases',
  [TrendVariableEnum.closedTransactions]: 'Cases',
  [TrendVariableEnum.totalTouches]: 'Touches',
  [TrendVariableEnum.avgTouches]: 'Touches',
}

const TIME_METRICS_SET = new Set([
  TrendVariableEnum.totalDuration,
  TrendVariableEnum.avgDuration,
  TrendVariableEnum.startedTransactionAvgDuration,
  TrendVariableEnum.startedTransactionMedianDuration,
  TrendVariableEnum.endedTransactionAvgDuration,
  TrendVariableEnum.endedTransactionMedianDuration,
  TrendVariableEnum.endedTransactionDurationStandardDeviation,
])

const Trends = () => {
  const { processId, processFilters: filters } = useQueryFilters()
  const {
    user: { isPlatformViewer },
  } = useDashboard()
  const activeTrendOptions = isPlatformViewer
    ? TREND_SELECT_OPTIONS_PLATFORM_VIEWER
    : TREND_SELECT_OPTIONS

  const [selectedVariable, setSelectedVariable] = useState<TrendVariableEnum>(
    activeTrendOptions[0].value,
  )
  const [selectedTrendGranularity, setSelectedTrendGranularity] =
    useState<TrendTimeGranularityEnum>(TREND_TIME_GRANUARITY[0].value)
  const [selectedChartData, setSelectedChartData] = useState<TrendData | null>(null)

  const chartUnit = useRef<string>('Cases')

  const { data, isFetching } = useGetTransactionTrendsQuery({
    processId,
    filters,
    trend_granularity: selectedTrendGranularity,
  })

  const chartData: ChartDataEntry[] = useMemo(() => {
    if (!data) return []

    let divider = 1
    let unit = null
    if (TIME_METRICS_SET.has(selectedVariable)) {
      const maxValue = Math.max(...(data.map((i) => i[selectedVariable]) || []))
      ;({ divider, unit } = getBestTimeFormatTransformationForSeconds(maxValue))
    } else {
      unit = VARIABLE_UNIT_MAPPER[selectedVariable as keyof typeof VARIABLE_UNIT_MAPPER]
    }

    chartUnit.current = unit
    return data.map((item) => {
      return getLabelForTrend(item, selectedTrendGranularity, divider, selectedVariable)
    })
  }, [data, selectedVariable, selectedTrendGranularity])

  const tooltipCallback = (tooltipItems: TooltipItem<keyof ChartTypeRegistry>) => {
    if (selectedTrendGranularity !== TrendTimeGranularityEnum.WEEK) {
      return `${formatNumber(tooltipItems.raw as string, 10)} ${chartUnit.current}`
    }
    const splittedLabel = tooltipItems.label.split('/')
    const weekNumber = Number(splittedLabel[0].substring(2))
    const year = Number(splittedLabel[1])
    return `${weekToDateString(weekNumber, year)}: ${formatNumber(
      tooltipItems.raw as string,
      10,
    )} ${chartUnit.current}`
  }

  const onBarClick = async (_: string, i: number) => {
    data ? setSelectedChartData(data[i]) : setSelectedChartData(null)
  }

  if (isFetching) return <Loader />

  if (!data) return <Text variant='h2'>No data found</Text>

  return (
    <>
      <BarChartAndTableDisplay
        data={chartData}
        unit={chartUnit.current}
        label='Trends'
        tableHeaderValues={['Week', 'value']}
        tooltipCallback={tooltipCallback}
        selectActions={[
          <Select
            size='xs'
            options={TREND_TIME_GRANUARITY}
            value={selectedTrendGranularity}
            onChange={setSelectedTrendGranularity as React.Dispatch<React.SetStateAction<string>>}
            key={String(chartData) + 'granularity'}
          />,
          <Select
            size='xs'
            options={activeTrendOptions}
            value={selectedVariable}
            onChange={setSelectedVariable as React.Dispatch<React.SetStateAction<string>>}
            key={String(chartData)}
          />,
        ]}
        onClick={TREND_METRICS_THAT_CAN_BE_FILTERED.has(selectedVariable) ? onBarClick : undefined}
        useReversedDataOrder
        isAllDataVisible
      />

      <TrendFilterModal
        open={Boolean(selectedChartData)}
        onClose={() => setSelectedChartData(null)}
        data={selectedChartData}
        selectedVariable={selectedVariable}
        selectedTrendGranularity={selectedTrendGranularity}
      />
    </>
  )
}

export default Trends
