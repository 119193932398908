import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useGetConformanceFlowsQuery } from '../../../../app/apiSlice'
import {
  useGetStateFlowChartQuery,
  useGetStepFlowChartQuery,
  useGetTeamFlowChartQuery,
  useGetWindowFlowChartQuery,
} from '../../../../app/apiSliceProcessApi'
import { useAppDispatch, useAppSelector, useQueryFilters } from '../../../../common/hooks'
import type { ServerTaskVisualizationNode } from '../../../TaskDiscovery/types'
import {
  selectHighlightSelections,
  selectVariantOrder,
  setStateVariantData,
  setStepVariantData,
} from '../../processesSlice'
import { ConformanceTypeEnum, type ZoomingFilters } from '../../types'
import { getProcessIdFromLocation } from '../../utils'
import { VariantViewRoutesEnum } from './enums'
import type {
  ServerEdge,
  ServerStateFlowNode,
  ServerStepFlowNode,
  ServerTeamFlowNode,
  StateVariantData,
  StepVariantData,
  TeamVariantData,
} from './types'
import { VariantVisualizationLevelEnum, ZoomModeEnum } from './types'
import { useModalConfigurations } from './useModalConfigurations'
import { useSelectedVariantsIndicies } from './useSelectedVariantsIndicies'
import { useZoomingFilters } from './useZoomingFilters'

export const useGetVariantData = (
  activeRoute: VariantViewRoutesEnum,
): {
  allVariantDataArray: TeamVariantData[] | StepVariantData[] | StateVariantData[]
  totalTransactionAmount: number
  nodes: Array<
    ServerTeamFlowNode | ServerStateFlowNode | ServerStepFlowNode | ServerTaskVisualizationNode
  >
  edges: ServerEdge[]
  visualizationLevel: VariantVisualizationLevelEnum
  isFetching: boolean
} => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { processFilters, processFiltersWithZooming } = useQueryFilters()
  const processId = getProcessIdFromLocation(location)
  const {
    stepLevelVariantIndices,
    stateLevelVariantIndices,
    teamLevelVariantIndices,
    windowLevelVariantIndices,
    updateSelectedVariantIndicies,
  } = useSelectedVariantsIndicies()
  const variantOrder = useAppSelector(selectVariantOrder)
  const { activeZoomMode } = useZoomingFilters()
  const { isStepModalOpen } = useModalConfigurations()
  const { isUnwindModeActive } = useAppSelector(selectHighlightSelections)

  const visualizationLevel = defineVisualizationLevel(
    activeRoute,
    activeZoomMode,
    processFiltersWithZooming.zooming_filters,
  )

  const selectedVariants = useMemo(() => {
    switch (visualizationLevel) {
      case VariantVisualizationLevelEnum.STEP:
        return stepLevelVariantIndices

      case VariantVisualizationLevelEnum.STATE:
        return stateLevelVariantIndices

      case VariantVisualizationLevelEnum.TEAM:
        return teamLevelVariantIndices

      case VariantVisualizationLevelEnum.WINDOW:
        return windowLevelVariantIndices

      default:
        return [0]
    }
  }, [
    visualizationLevel,
    stepLevelVariantIndices,
    stateLevelVariantIndices,
    teamLevelVariantIndices,
    windowLevelVariantIndices,
  ])

  const { data: stepConformance, isFetching: isFetchingStepConformance } =
    useGetConformanceFlowsQuery({
      processId,
      conformanceType: ConformanceTypeEnum.STEP_FLOW,
    })
  const { data: stateConformance, isFetching: isFetchingStateConformance } =
    useGetConformanceFlowsQuery({
      processId,
      conformanceType: ConformanceTypeEnum.STATE_FLOW,
    })

  const queryOptionsStep = {
    processId,
    filters: processFiltersWithZooming,
    selected_variants: selectedVariants,
    variant_order: variantOrder,
    is_unwind_steps_mode: isUnwindModeActive,
    conformance_flows: (stepConformance ?? []).map((c) => c.steps),
  }
  const queryOptionsState = {
    processId,
    filters: processFilters,
    selected_variants: selectedVariants,
    variant_order: variantOrder,
    conformance_flows: (stateConformance ?? []).map((c) => c.steps),
  }
  const queryOptionsTeam = {
    processId,
    filters: processFilters,
    selected_variants: selectedVariants,
    variant_order: variantOrder,
  }
  const queryOptionsWindow = {
    processId,
    filters: processFiltersWithZooming,
    selected_variants: selectedVariants,
    variant_order: variantOrder,
    is_multi_system_flow: true,
    is_task_path_visualization: selectedVariants.length === 1,
    conformance_flows: [],
  }

  const { data: stepFlowChartData, isFetching: isFetchingStepData } = useGetStepFlowChartQuery(
    queryOptionsStep,
    {
      skip:
        visualizationLevel !== VariantVisualizationLevelEnum.STEP ||
        isStepModalOpen ||
        isFetchingStepConformance,
    },
  )
  const { data: stateFlowChartData, isFetching: isFetchingStateData } = useGetStateFlowChartQuery(
    queryOptionsState,
    {
      skip:
        visualizationLevel !== VariantVisualizationLevelEnum.STATE ||
        isStepModalOpen ||
        isFetchingStateConformance,
    },
  )
  const { data: teamFlowChartData, isFetching: isFetchingTeamData } = useGetTeamFlowChartQuery(
    queryOptionsTeam,
    {
      skip: visualizationLevel !== VariantVisualizationLevelEnum.TEAM || isStepModalOpen,
    },
  )
  const { data: windowFlowChartData, isFetching: isFetchingWindowData } =
    useGetWindowFlowChartQuery(queryOptionsWindow, {
      skip: visualizationLevel !== VariantVisualizationLevelEnum.WINDOW || isStepModalOpen,
    })

  const isFetching =
    isFetchingStepData || isFetchingStateData || isFetchingTeamData || isFetchingWindowData

  const activeData = useMemo(() => {
    let data
    switch (visualizationLevel) {
      case VariantVisualizationLevelEnum.STEP:
        data = stepFlowChartData
        break

      case VariantVisualizationLevelEnum.STATE:
        data = stateFlowChartData
        break

      case VariantVisualizationLevelEnum.TEAM:
        data = teamFlowChartData
        break

      case VariantVisualizationLevelEnum.WINDOW:
        data = windowFlowChartData
        break

      default:
        data = null
        break
    }
    const { nodes, edges, variant_list, total_transactions } = data || {
      variant_list: [],
      total_transactions: 0,
      nodes: [],
      edges: [],
    }

    return {
      allVariantDataArray: variant_list,
      totalTransactionAmount: total_transactions,
      nodes,
      edges,
      visualizationLevel,
      isFetching,
    }
  }, [
    visualizationLevel,
    stepFlowChartData,
    stateFlowChartData,
    teamFlowChartData,
    windowFlowChartData,
    isFetching,
  ])

  useEffect(() => {
    // When updating data, the variant amount might be smaller than previously.
    // Hence some of the selected indexes might be outside of the possible
    // range of indexes. Remove indexes that are not in range.
    if (!activeData || isFetching) return
    const filteredVariants = selectedVariants.filter(
      (i) => activeData && i < activeData.allVariantDataArray.length,
    )

    if (selectedVariants.length !== filteredVariants.length) {
      updateSelectedVariantIndicies(filteredVariants, visualizationLevel)
    }
  }, [activeData, isFetching, selectedVariants, updateSelectedVariantIndicies, visualizationLevel])

  useEffect(() => {
    if (!stepFlowChartData) return
    const data = [...stepLevelVariantIndices]
      .filter((i) => i < stepFlowChartData.variant_list.length ?? 0)
      .map((i) => stepFlowChartData.variant_list[i])

    dispatch(setStepVariantData(data))
  }, [stepFlowChartData, dispatch, stepLevelVariantIndices])

  useEffect(() => {
    if (!stateFlowChartData) return
    const data = [...stateLevelVariantIndices]
      .filter((i) => i < stateFlowChartData.variant_list.length)
      .map((i) => stateFlowChartData.variant_list[i])

    dispatch(setStateVariantData(data))
  }, [stateFlowChartData, dispatch, stateLevelVariantIndices])

  return activeData
}

const defineVisualizationLevel = (
  activeRoute: VariantViewRoutesEnum,
  zoomMode: ZoomModeEnum | null,
  zoomingFilters: ZoomingFilters | undefined,
) => {
  if (zoomMode === ZoomModeEnum.WINDOW && zoomingFilters?.step_name)
    return VariantVisualizationLevelEnum.WINDOW
  if (zoomMode === ZoomModeEnum.STEP && zoomingFilters?.state_name)
    return VariantVisualizationLevelEnum.STEP
  if (activeRoute === VariantViewRoutesEnum.STEP_FLOWS) {
    return VariantVisualizationLevelEnum.STEP
  }
  if (activeRoute === VariantViewRoutesEnum.STATE_FLOWS) {
    return VariantVisualizationLevelEnum.STATE
  }
  return VariantVisualizationLevelEnum.TEAM
}
