import type { JsonObject, Tag } from '../../common/types/common'
import { ReactionTriggersEnum } from '../ConfigureDataCollectionAdminPanelPage/types'

export enum AdvancedSetupSubPathEnum {
  REQUIREMENT_FILE_MANAGEMENT = 'requirement-file-management',
  CONFIG_FILE_MANAGEMENT = 'config-file-management',
  X_RAY_TOOLS = 'x-ray-tools',
  X_RAY_PATTERN_TESTER = 'x-ray-pattern-tester',
}

export interface DataCollectionTestCaseFormData {
  // APP REQUIREMENT DATA
  processName?: string | undefined
  url?: string | undefined
  title?: string | undefined
  processIdentifierName?: string | undefined
  processIdentifierValue?: string | undefined

  uiFieldKey: string | null
  uiFieldValue?: string | undefined
}

export interface DataCollectionRuleFormData {
  inProcessName?: string | undefined
  inUrl?: { value: string }[] | undefined
  inTitle?: { value: string }[] | undefined

  processName?: string | undefined
  processIdPathBefore?: string | undefined
  processIdParamKey?: string | undefined
  processIdTitleBefore?: string | undefined
}

export interface DataCollectionFromUIElementRuleFormData {
  [inUiElement: string]: string[]
}

export interface WindowChangeTriggerRuleFormData {
  triggerName?: string | undefined
  reaction?: string | undefined
}

export interface ExtractIdentifierRule {
  id: string
  identifier_name: string
  key?: string | null
  regex_pattern?: string | null
  compiled_regex?: string | null
  hash_identifier: boolean
  from_fields?: string[] | null
}

export interface MatchingCriteriaContext {
  in_url?: string[]
  not_in_url?: string[]
  in_title?: string[]
  not_in_title?: string[]
  in_process_name?: string[]
  not_in_process_name?: string[]

  // UI element matching related fields
  [key: string]: string[] | string
}

export interface MatchingCriteria {
  rule_engine_rule: string
  context: MatchingCriteriaContext
}

export interface DashboardContext {
  app_name?: string | undefined | null
  window_name?: string | undefined | null
  process_name?: string | undefined | null
  window_change_trigger_name?: string | undefined | null
}

interface RawStepType {
  [key: string]: any
}

export type RawPatternType = RawStepType[]

export interface Reaction {
  on: (ReactionTriggersEnum | string)[]
  react: RawPatternType
}

export interface RuleIn {
  id: string
  tags: Tag[]
  allow_tracking: boolean
  extract_identifiers: ExtractIdentifierRule[]
  salvage_fields: string[]
  reactions: Reaction[]
  matching_criteria: MatchingCriteria
  description?: string
  is_dashboard_generated?: boolean
  dashboard_context?: DashboardContext
}

export interface AddReactionFormData {
  windowName: string
  reaction: string
  elementName: string
}

interface PatternXrayTestCase {
  x_ray_dict: JsonObject
  expected_value: string
}

export interface PatternXrayTestBody {
  pattern: RawPatternType
  x_ray_test_cases: PatternXrayTestCase[]
}

export interface XrayPatternTestCaseResult {
  is_passed: boolean
  error_message: string | null
}

export interface XRayPatternTestCaseRenderData {
  xRayJson: JsonObject | null
  expectedValue: string
  formFileError: string | null
  formExpectedValueError: string | null
  testResultError: string | null
  isTestedSuccesfully: boolean
}
