import { yupResolver } from '@hookform/resolvers/yup'
import { Resolver, useForm } from 'react-hook-form'

import ItemAccordion from '../../../features/ConfigureDataCollectionAdminPanelPage/ItemAccordion'
import type { ConfigTestCase } from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import type { DataCollectionTestCaseFormData } from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { Text } from '../../designs'
import DataCollectionTestForm from '../DataCollectionForms/DataCollectionTestForm'
import { testCaseFormSchema } from '../DataCollectionForms/formSchemas'
import AddNewFormDisplay from './AddNewFormDisplay'
import DataCollectionTestDisplay from './DataCollectionTestDisplay'
import type { TestCaseWithTestResult } from './types'

interface Props {
  testCases: TestCaseWithTestResult[]
  uiFieldNames?: string[]
  onAddTestCase: (formValues: DataCollectionTestCaseFormData) => Promise<void>
  onUpdateTestCase: (
    formValues: DataCollectionTestCaseFormData,
    configTestCase: ConfigTestCase,
  ) => Promise<void>
  onDeleteTestCase: (configTestCase: ConfigTestCase) => Promise<void>
}

const DataCollectionTests = ({
  testCases,
  uiFieldNames = [],
  onAddTestCase,
  onUpdateTestCase,
  onDeleteTestCase,
}: Props) => {
  // Form control for adding a new test
  const { control, handleSubmit } = useForm<DataCollectionTestCaseFormData>({
    resolver: yupResolver(testCaseFormSchema) as Resolver<DataCollectionTestCaseFormData>,
    defaultValues: {
      processName: undefined,
      url: undefined,
      title: undefined,
      uiFieldKey: uiFieldNames.length > 0 ? uiFieldNames[0] : null,
      uiFieldValue: undefined,
    },
  })

  // Generate DataCollectionRequirementAppDisplay components for accordion.
  const accordionData = testCases.map((testCase, i) => {
    const label = testCase.description ?? `Test Case ${i}`
    return {
      label,
      key: label,
      hasError: testCase.isFailing,
      hasWarning: testCase.isNotTested,
      component: (
        <DataCollectionTestDisplay
          configTestCase={testCase}
          uiFieldNames={uiFieldNames}
          hasError={testCase.isFailing}
          isNotTested={testCase.isNotTested}
          errors={testCase.errors}
          onDeleteTestCase={onDeleteTestCase}
          onUpdateTestCase={onUpdateTestCase}
        />
      ),
    }
  })

  return (
    <div className='space-y-4'>
      <div className='overflow-hidden bg-white px-4 py-2 shadow sm:rounded-lg'>
        <Text className='mb-2' weight={500} color='darkGray'>
          Data Collection Test
        </Text>
        <div>
          <ItemAccordion data={accordionData} />
        </div>
      </div>

      <AddNewFormDisplay onSubmit={handleSubmit(onAddTestCase)} label='Add New Test Case'>
        <DataCollectionTestForm control={control} uiFieldNames={uiFieldNames} />
      </AddNewFormDisplay>
    </div>
  )
}

export default DataCollectionTests
