import { ArrowUpOnSquareStackIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import { Button } from '../../common/designs'
import ExportTaskVariantsModal from './ExportTaskVariantsModal'
import type { TaskVariantData } from './types'

interface Props {
  taskVariantData: TaskVariantData
}

const ExportTaskVariantsButton = ({ taskVariantData }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        variant='white'
        size='xs'
        iconStart={<ArrowUpOnSquareStackIcon />}
        onClick={() => setIsModalOpen(true)}
        className='pointer-events-auto'
      >
        Export Variants
      </Button>

      <ExportTaskVariantsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        taskVariantData={taskVariantData}
      />
    </>
  )
}

export default ExportTaskVariantsButton
