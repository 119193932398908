import { GITBOOK_DOCUMENTATOIN_BASE_URL } from '../../app/constants'
import { Divider, Text } from '../../common/designs'
import GetStartedSection from './GetStartedSection'

const LANDSCAPE_ANALYSIS_LINK = `${GITBOOK_DOCUMENTATOIN_BASE_URL}/data-collection/data-collection-studio#defining-settings-for-digital-landscape-analysis`
const UNSTRUCTURED_PROCESS_ANALYSIS_LINK = `${GITBOOK_DOCUMENTATOIN_BASE_URL}/data-collection/data-collection-studio#defining-settings-for-unstructured-process-analysis`
const STRUCTURED_PROCESS_ANALYSISS_LINK = `${GITBOOK_DOCUMENTATOIN_BASE_URL}/data-collection/data-collection-studio#defining-settings-for-structured-process-analysis`

const GetStarted = () => {
  return (
    <div className='space-y-12 lg:p-5'>
      <div className='max-w-[940px] space-y-4'>
        <Text size='3xl' weight={600} className='mb-3'>
          Get Started With Process Intelligence Data Collection
        </Text>
        Welcome to the Process Intelligence Admin Panel, your starting point for enabling system,
        task, and process data collection.
        <Text>
          It&apos;s recommended to start with a basic setup and gradually enhance your data
          collection capabilities. This approach allows you to build your data collection maturity
          over time, leading to full process intelligence features.
        </Text>
        <Text>
          The tool supports three main analytic levels: Digital Landscape Analysis, Unstructured
          Process Analysis, and Structured Process Analysis. Below, you&apos;ll find detailed
          instructions for each phase to get started with the data collection.
        </Text>
      </div>
      <Divider />

      <GetStartedSection
        title='DIGITAL LANDSCAPE ANALYSIS'
        description='Optimize the use of resources, time & cost.'
        orderString='01'
        instructionsLink={LANDSCAPE_ANALYSIS_LINK}
      />

      <GetStartedSection
        title='UNSTRUCTURED PROCESS ANALYSIS'
        description='Discover and improve undocumented processes.'
        orderString='02'
        instructionsLink={UNSTRUCTURED_PROCESS_ANALYSIS_LINK}
      />

      <GetStartedSection
        title='STRUCTURED PROCESS ANALYSIS'
        description='Follow specific process objects to improve operations.'
        orderString='03'
        instructionsLink={STRUCTURED_PROCESS_ANALYSISS_LINK}
      />
    </div>
  )
}

export default GetStarted
