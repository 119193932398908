import { ServerTaskVisualizationNode } from '../../../TaskDiscovery/types'
import type { TransactionFilters, TransactionFiltersWithZooming, VariantOrder } from '../../types'
import { NodeTypeEnum } from './enums'

export interface ServerMetricsInStep {
  group_name: string
  duration_sec: number
  duration_percentage: number
  visit_count: number
  visit_percentage: number
}

export interface StepVariantHighlightingOptions {
  filters: TransactionFiltersWithZooming
  selected_variants: Array<string[]>
  processId: string
  is_unwind_steps_mode?: boolean
}

export interface ServerStepFlowNode {
  display_name: string
  step_index: number
  step_name: string

  // Metrics
  variants: number
  transactions: number
  duration_sec: number
  avg_duration_sec: number
  total_step_visits: number

  // Activity data
  relative_share_of_transactions: number
  relative_time_per_transaction: number

  // Visualization
  node_type: NodeTypeEnum
  is_conformance_flow_node: boolean
  column_index: number
  row_index: number
  x_coord: number
  y_coord: number
  top_ports: PortData[]
  right_ports: PortData[]
  bottom_ports: PortData[]
  left_ports: PortData[]
  is_preparation_step: boolean
}

export interface ServerTeamFlowNode {
  display_name: string
  transaction_ids: string[]
  team_id: string | null
  step_position: number

  // Metrics
  variants: number
  transactions: number

  relative_share_of_transactions: number

  // Visualization
  node_type: NodeTypeEnum
  is_conformance_flow_node: boolean
  column_index: number
  row_index: number
  x_coord: number
  y_coord: number
  top_ports: PortData[]
  right_ports: PortData[]
  bottom_ports: PortData[]
  left_ports: PortData[]
}

export interface ServerStateFlowNode {
  display_name: string
  transaction_ids: string[]
  team_id: string | null
  step_position: number

  // Metrics
  variants: number
  transactions: number
  total_duration_sec: number
  avg_duration_sec: number
  relative_share_of_transactions: number
  relative_time_per_transaction: number

  // Visualization
  node_type: NodeTypeEnum
  is_conformance_flow_node: boolean
  column_index: number
  row_index: number
  x_coord: number
  y_coord: number
  top_ports: PortData[]
  right_ports: PortData[]
  bottom_ports: PortData[]
  left_ports: PortData[]
}

export interface ServerEdge {
  id: string
  total_transactions: number
  source: string
  target: string
  source_port: string
  target_port: string
  average_time_sec: number
  relative_share_of_transactions: number
  relative_average_time_sec: number
}

export interface StepVariantData {
  transactions: number
  step_amount: number
  avg_throughput_time_sec: number
  avg_active_work_sec: number
  avg_touch_amount: number
  is_conformance_flow: boolean
  steps: string[]
}

export interface ServerStepVariantData {
  variant_list: StepVariantData[]
  total_transactions: number
  nodes: ServerStepFlowNode[]
  edges: ServerEdge[]
}

export interface TeamVariantData {
  transactions: number
  step_amount: number
  avg_throughput_time_sec: number
  is_conformance_flow: boolean
  steps: string[]
}

export interface ServerTeamVariantData {
  variant_list: TeamVariantData[]
  total_transactions: number
  nodes: ServerTeamFlowNode[]
  edges: ServerEdge[]
}

export interface StateVariantData {
  transactions: number
  step_amount: number
  avg_throughput_time_sec: number
  is_conformance_flow: boolean
  steps: string[]
}

export interface ServerStateVariantData {
  variant_list: StateVariantData[]
  total_transactions: number
  nodes: ServerStateFlowNode[]
  edges: ServerEdge[]
}

export interface PortData {
  id: string
  type: 'source' | 'target'
}

export interface EdgeDataPayload {
  relativeShareOfTransactions: number
}

export interface TeamAndStateProcessMiningOptions {
  filters: TransactionFilters
  selected_variants: number[]
  variant_order: VariantOrder
  processId: string
  conformance_flows?: Array<string[]>
}
export interface StepProcessMiningOptions {
  filters: TransactionFiltersWithZooming
  selected_variants: number[]
  variant_order: VariantOrder
  processId: string
  is_unwind_steps_mode?: boolean
  conformance_flows: Array<string[]>
}

export interface WindowProcessMiningOptions extends StepProcessMiningOptions {
  is_multi_system_flow: boolean
  is_task_path_visualization?: boolean
  max_steps_before?: number
  max_steps_after?: number
}

interface ImprovementPotential {
  total_activities: number
  leadtime_to_step_sec: number
  worktime_sec: number
  leadtime_to_step_sec_avg: number
  annual_worktime_sec: number
}

export interface ActivityImprovementData {
  improvementPotential: ImprovementPotential
  label: string
}

interface StepActivityCategory {
  improvement_potential: ImprovementPotential
  activity_percentage: number
}

interface MultiSystemWorkflow {
  session_id: string
  first_window_timestamp: Date
  last_window_timestamp: Date
}

interface MultiSystemWorkflowCategory extends StepActivityCategory {
  flows: MultiSystemWorkflow[]
}

export interface ServerStepInsightsActivities {
  quick_processing: StepActivityCategory
  focused_work: StepActivityCategory
  multi_system_workflows: MultiSystemWorkflowCategory
}

export interface StepActivityQueryOptions {
  filters: TransactionFiltersWithZooming
  step_name: string
  processId: string
}

export interface TaskMiningOptions {
  filters: TransactionFilters
  processId: string
  step_name: string
}

export interface UserTask {
  window_name: string
  total_count: number
  total_duration_sec: number
}

export interface NodeDistributionData {
  color?: string
  colorIndex?: number
  name: string
  percentage: number
  total: string
}

export enum VariantVisualizationLevelEnum {
  TEAM = 'team',
  STATE = 'state',
  STEP = 'step',
  WINDOW = 'window',
}

export enum ZoomModeEnum {
  STEP = 'step',
  WINDOW = 'window',
}

export enum AutomationInsightNameEnum {
  IDP = 'IDP',
  TEMPLATIZATION = 'Templatization',
  RPA = 'RPA',
  GEN_AI = 'GenAI',
}

export interface AutomationInsight {
  insight_name: AutomationInsightNameEnum
  duration_sec: number
  duration_percentage: number
}

export type ServerNodeTypes =
  | ServerTaskVisualizationNode
  | ServerStepFlowNode
  | ServerTeamFlowNode
  | ServerStateFlowNode

export interface ExportVariantsFormData {
  processName: string
  processDescription: string
}
