import type { RawPatternType } from '../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import type { DimensionFilterOption } from '../../features/Processes/ProcessMining/Filters/types'

export const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean'
export const isString = (value: unknown): value is string => {
  return typeof value === 'string'
}
export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number'
}

export const isArray = (value: unknown): value is unknown[] => Array.isArray(value)
export const isNumberArray = (value: unknown): value is number[] => {
  return isArray(value) && value.every((item) => typeof item === 'number')
}
export const isStringArray = (value: unknown): value is string[] => {
  return isArray(value) && value.every((item) => typeof item === 'string')
}

export const isArrayOfStringArrays = (value: unknown): value is string[][] => {
  return isArray(value) && value.every((item) => isStringArray(item))
}
export const isDimensionFilterOption = (value: unknown): value is DimensionFilterOption => {
  return isString(value) || isNumber(value) || isStringArray(value)
}
export const isDimensionFilterOptionArray = (value: unknown): value is DimensionFilterOption[] => {
  return isArray(value) && value.every((item) => isDimensionFilterOption(item))
}
export const isRawPatternType = (value: unknown): value is RawPatternType => {
  return isArray(value) && value.every((item) => typeof item === 'object')
}

export const ensure = <T>(
  arg: T | undefined | null,
  message = 'This value was promised to be there.',
): T => {
  if (arg === undefined || arg === null) {
    throw new TypeError(message)
  }

  return arg
}
