import type { ChartOptions, ChartTypeRegistry, TooltipItem } from 'chart.js'
import { createRef, useEffect, useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'

import { useGetTransactionTrendsQuery } from '../../../../app/apiSliceProcessApi'
import { ORANGE_300_HEX, PRIMARY_600_HEX } from '../../../../app/theme'
import { InlineLoader } from '../../../../common/components'
import { Select } from '../../../../common/designs'
import { useQueryFilters } from '../../../../common/hooks'
import { cssCalcPixels } from '../../../../common/utils/stringFormatting'
import { TrendTimeGranularityEnum, TrendVariableEnum } from '../../types'
import { TREND_TIME_GRANUARITY } from '../TrendConstants'
import { getLabelForTrend } from '../TrendHelpers'

const CHART_HEIGHT = '400px'
const CHART_WIDTH = '100%'

export const TransactionStandardDeviationTrend = () => {
  const { processId, processFilters: filters } = useQueryFilters()

  const [chartHeight, setChartHeight] = useState<string>('0px')
  const [selectedTrendGranularity, setSelectedTrendGranularity] =
    useState<TrendTimeGranularityEnum>(TREND_TIME_GRANUARITY[2].value)
  const legendRef = createRef<HTMLDivElement>()
  const dataSelectorRef = createRef<HTMLDivElement>()

  const { data: trendData, isFetching } = useGetTransactionTrendsQuery({
    processId,
    filters,
    trend_granularity: selectedTrendGranularity,
  })

  useEffect(() => {
    const extraSpace =
      (legendRef.current?.clientHeight || 0) + (dataSelectorRef.current?.clientHeight || 0)
    setChartHeight(cssCalcPixels(CHART_HEIGHT, extraSpace, '-'))
  }, [legendRef, dataSelectorRef, setChartHeight])

  const { chartData, options } = useMemo(() => {
    const labels = [] as string[]
    const valuesAvg = [] as number[]
    const valuesAvgPlusStDev = [] as number[]
    const valuesAvgMinusStDev = [] as number[]

    const divider = 60 * 60
    const unit = 'hours'

    ;(trendData ?? []).forEach((trend) => {
      const { label } = getLabelForTrend(
        trend,
        selectedTrendGranularity,
        divider,
        TrendVariableEnum.endedTransactionAvgDuration,
      )
      labels.push(label)
      const avgValue = trend.ended_avg_throughput_time_sec
      const stdDevValue = trend.ended_standard_deviation_throughput_time_sec
      valuesAvg.push(avgValue / divider)
      valuesAvgPlusStDev.push((avgValue + stdDevValue) / divider)
      valuesAvgMinusStDev.push((avgValue - stdDevValue) / divider)
    })

    const yMaxValue = Math.max(...valuesAvgPlusStDev) * 1.1

    const options = {
      scales: {
        y: {
          suggestedMax: yMaxValue,
          ticks: {
            maxTicksLimit: 15,
            font: {
              family: 'Inter',
            },
          },
          grid: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            maxTicksLimit: 30,
            callback: function (val: number) {
              return `${this.getLabelForValue(val)}`
            },
            font: {
              family: 'Inter',
            },
          },
        },
      },
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
              return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} ${unit}`
            },
            title: (tooltipItems: any[]) => `${tooltipItems[0].label}`,
          },
        },
      },
    } as ChartOptions<'line'>

    const chartData = {
      labels,
      datasets: [
        {
          label: 'Average Duration',
          data: valuesAvg,
          fill: false,
          borderColor: PRIMARY_600_HEX,
          backgroundColor: PRIMARY_600_HEX,
        },
        {
          label: 'Average Duration + 1 Standard Deviation',
          data: valuesAvgPlusStDev,
          fill: false,
          borderColor: ORANGE_300_HEX,
          backgroundColor: ORANGE_300_HEX,
          borderDash: [10, 5],
        },
        {
          label: 'Average Duration - 1 Standard Deviation',
          data: valuesAvgMinusStDev,
          fill: false,
          borderColor: ORANGE_300_HEX,
          backgroundColor: ORANGE_300_HEX,
          borderDash: [10, 5],
        },
      ],
    }

    return {
      chartData,
      options,
    }
  }, [trendData, selectedTrendGranularity])

  return (
    <>
      {isFetching ? (
        <InlineLoader />
      ) : (
        <div>
          <Select
            className='w-48'
            size='xs'
            options={TREND_TIME_GRANUARITY}
            value={selectedTrendGranularity}
            onChange={setSelectedTrendGranularity as React.Dispatch<React.SetStateAction<string>>}
          />
          {chartHeight !== '0px' ? (
            <div style={{ height: CHART_HEIGHT, width: CHART_WIDTH }}>
              <div style={{ height: chartHeight, width: CHART_WIDTH }}>
                <Line data={chartData} options={options} />
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

export default TransactionStandardDeviationTrend

