import {
  useGetTransactionGroupQuery,
  useGetTransactionMetricsQuery,
  useGetTransactionTrendsQuery,
} from '../../../app/apiSliceProcessApi'
import { DEFAULT_DATE_FORMATTING_LOCALE } from '../../../app/constants'
import {
  BarChart,
  ContentDisplay,
  HorizontalBar,
  InlineLoader,
  Stats,
} from '../../../common/components'
import { Text } from '../../../common/designs'
import { useQueryFilters } from '../../../common/hooks'
import { formatNumber, getBestTimeFormatForSeconds } from '../../../common/utils/numberFormatting'
import { GroupByFieldTypeEnum, MetricNamesEnum, TrendTimeGranularityEnum } from '../types'
import { TagFilteringLogicEnum } from './Analysis/types'
import { TREND_DATE_FORMAT_OPTIONS } from './TrendConstants'

const OverviewPlatformViewer = () => {
  const { processFilters: filters, processId } = useQueryFilters()
  const params = { processId, filters }

  const isClosedFilter = {
    tag_name: 'is_closed',
    tag_filtering_logic: TagFilteringLogicEnum.LATEST_VALUE,
    values: ['True'],
  }

  const { data: isClosedData, isFetching: isFetchingIsClosed } = useGetTransactionGroupQuery({
    processId,
    filters,
    group_by: [
      {
        field_type: GroupByFieldTypeEnum.TAG_NAME,
        tag_name: 'is_closed',
        tag_filtering_logic: TagFilteringLogicEnum.LATEST_VALUE,
      },
    ],
    metrics: [MetricNamesEnum.TOTAL_TRANSACTIONS],
  })
  const { data: isClosedInTimeData, isFetching: isFetchingIsClosedInTimeData } =
    useGetTransactionGroupQuery({
      processId,
      filters: { ...filters, tag_filters: [...(filters.tag_filters ?? []), isClosedFilter] },
      group_by: [
        {
          field_type: GroupByFieldTypeEnum.TAG_NAME,
          tag_name: 'in_time',
          tag_filtering_logic: TagFilteringLogicEnum.LATEST_VALUE,
        },
      ],
      metrics: [MetricNamesEnum.TOTAL_TRANSACTIONS],
    })
  const { data: metricData, isFetching: isFetchingMetricData } = useGetTransactionMetricsQuery({
    ...params,
    metrics: [
      MetricNamesEnum.TOTAL_TRANSACTIONS,
      MetricNamesEnum.AVG_THROUGHPUT_TIME,
      MetricNamesEnum.AVG_STEP_AMOUNT,
    ],
  })

  const { data: metricDataNoFilter, isFetching: isFetchingMetricDataNoFilter } =
    useGetTransactionMetricsQuery({
      processId,
      filters: {},
      metrics: [MetricNamesEnum.TOTAL_TRANSACTIONS],
    })

  const { data: trendData, isFetching: isFetchingTrendData } = useGetTransactionTrendsQuery({
    processId,
    filters,
    trend_granularity: TrendTimeGranularityEnum.DATE,
  })

  const totalTransactions = metricDataNoFilter?.[MetricNamesEnum.TOTAL_TRANSACTIONS] || 0
  const analyzedTransactions = metricData?.[MetricNamesEnum.TOTAL_TRANSACTIONS] || 0
  const filterdOutTransaction = totalTransactions - analyzedTransactions

  const transactionMetrics = [
    { label: 'All detected cases', value: formatNumber(totalTransactions) },
    { label: 'Cases in analysis', value: formatNumber(analyzedTransactions) },
    { label: 'Filtered out cases', value: formatNumber(filterdOutTransaction) },
  ]

  const timeMetrics = [
    {
      label: 'Avg. Duration',
      value: `${getBestTimeFormatForSeconds(
        metricData?.[MetricNamesEnum.AVG_THROUGHPUT_TIME] || 0,
      )}`,
    },
    {
      label: 'Steps per case',
      value: `${formatNumber(metricData?.[MetricNamesEnum.AVG_STEP_AMOUNT] || 0)}`,
    },
  ]

  const isClosedLabelMapper = { False: 'Active', True: 'Closed' } as { [key: string]: string }
  const isClosedChartData = isClosedData?.[MetricNamesEnum.TOTAL_TRANSACTIONS]?.map(
    ({ value, label }) => {
      return {
        label: isClosedLabelMapper[label as string] ?? 'Closed',
        value: value,
      }
    },
  )
  const isClosedInTimeChartData = isClosedInTimeData?.[MetricNamesEnum.TOTAL_TRANSACTIONS]?.map(
    ({ value, label }) => {
      return {
        label: label as string,
        value: value,
      }
    },
  )

  const closedDurationTrendChartData = (trendData ?? []).map((item) => {
    const label = new Date(
      item.year as number,
      // Server month numbers are from 1-12, Date-object expects them to be 0-11, hence the -1 here
      (item.monthNumber as number) - 1,
      item.dayNumber as number,
    ).toLocaleString(DEFAULT_DATE_FORMATTING_LOCALE, TREND_DATE_FORMAT_OPTIONS)

    return {
      label,
      value: item.ended_avg_throughput_time_sec / 3600,
    }
  })

  return (
    <div className='mb-10 flex flex-col gap-5'>
      <div>
        <Stats
          data={transactionMetrics}
          title='Case volumes'
          description='Case selected for the analysis is based on the active filter'
          isLoading={isFetchingMetricDataNoFilter}
        />
      </div>

      <div className='flex flex-col gap-5'>
        <div className='flex w-full gap-3'>
          <ContentDisplay className='w-full px-4 py-3'>
            <Text size='l' weight={600} className='mb-2'>
              Active vs. Closed Cases
            </Text>
            <HorizontalBar
              data={isClosedChartData || []}
              unit='cases'
              maxVisualizedValues={4}
              showLabels
              isLoading={isFetchingIsClosed}
            />
          </ContentDisplay>
          <ContentDisplay className='w-full px-4 py-3'>
            <Text size='l' weight={600} className='mb-2'>
              Closed on Time
            </Text>
            <HorizontalBar
              data={isClosedInTimeChartData || []}
              unit='cases'
              maxVisualizedValues={4}
              showLabels
              isLoading={isFetchingIsClosedInTimeData}
            />
          </ContentDisplay>
        </div>
      </div>

      <div>
        <Stats
          data={timeMetrics}
          title='Process KPIs'
          description='Based on the active filter'
          isLoading={isFetchingMetricData}
        />
      </div>

      <div>
        {isFetchingTrendData ? (
          <InlineLoader />
        ) : (
          <ContentDisplay className='w-full px-4 py-3'>
            <Text size='l' weight={600} className='mb-2'>
              Average Duration By Completion Date
            </Text>
            <BarChart data={closedDurationTrendChartData} unit={'hours'} />
          </ContentDisplay>
        )}
      </div>
    </div>
  )
}

export default OverviewPlatformViewer
