import { useGetProcessUserActionAnalysisQuery } from '../../../app/apiSliceProcessApi'
import { Loader } from '../../../common/components'
import { useQueryFilters } from '../../../common/hooks'
import { UserActionTypeEnum, VariableLabelEnum } from '../types'
import type { UserActivitiesData } from '../types'
import UserActivitiesBarchart from './UserActivitiesBarchart'

const CHECKBOX_OPTIONS = [
  {
    label: VariableLabelEnum.APPLICATION,
    value: VariableLabelEnum.APPLICATION,
  },
  {
    label: VariableLabelEnum.WINDOW,
    value: VariableLabelEnum.WINDOW,
  },
  {
    label: VariableLabelEnum.FIELD_NAME,
    value: VariableLabelEnum.FIELD_NAME,
  },
]

const DEFAULT_SELECTED_VALUES = [
  VariableLabelEnum.APPLICATION,
  VariableLabelEnum.WINDOW,
  VariableLabelEnum.FIELD_NAME,
]

interface Props {
  data: UserActivitiesData[] | undefined
  isFetching: boolean
}

export const UserActivitiesFieldChangeView = ({ data, isFetching }: Props) => {
  if (isFetching) return <Loader />

  return (
    <UserActivitiesBarchart
      checkboxOptions={CHECKBOX_OPTIONS}
      defaultSelectedValues={DEFAULT_SELECTED_VALUES}
      data={data ?? []}
    />
  )
}

const UserActivitiesFieldChange = () => {
  const { processFilters: filters, processId } = useQueryFilters()
  const { data, isFetching } = useGetProcessUserActionAnalysisQuery({
    filters,
    processId,
    action_type: UserActionTypeEnum.FIELD_EDIT,
  })

  return <UserActivitiesFieldChangeView data={data} isFetching={isFetching} />
}

export default UserActivitiesFieldChange
