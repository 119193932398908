import { useEffect, useState } from 'react'

import { useGetProcessIdentifierMapperQuery } from '../../app/apiSlice'
import { useGetTaskDiscoveryListQuery } from '../../app/apiSliceProcessApi'
import { Loader } from '../../common/components'
import { Checkboxes, Text } from '../../common/designs'
import { useQueryFilters } from '../../common/hooks'
import { PercentageValue } from '../../common/types/common'
import TaskListFilterMenu from './TaskListFilterMenu'
import TaskListItem from './TaskListItem'
import TaskListItemExtraContent from './TaskListItemExtraContent'
import { TaskTypeEnum } from './types'
import { useGetTaskFiltersFromSearch } from './useGetTaskFiltersFromSearch'

const TaskListView = () => {
  const { min_date, max_date } = useQueryFilters()
  const activeTaskFilters = useGetTaskFiltersFromSearch()
  const [openAccordions, setOpenAccordions] = useState<boolean[]>([])
  const [showedTaskTypes, setShowedTaskTypes] = useState<string[]>([
    'are_repetitive_tasks_visible',
    'are_predifined_tasks_visible',
    'are_work_flow_tasks_visible',
  ])

  const {
    data: taskArray,
    isLoading,
    isFetching,
  } = useGetTaskDiscoveryListQuery({
    filters: { ...activeTaskFilters, min_date, max_date },
  })
  const { data: processMapper } = useGetProcessIdentifierMapperQuery()

  useEffect(() => {
    if (taskArray) setOpenAccordions(taskArray.map((_) => false))
  }, [taskArray])

  const updateOpenAccordions = (index: number) => {
    const openAccordionsCopy = [...openAccordions]
    openAccordionsCopy[index] = !openAccordionsCopy[index]
    setOpenAccordions(openAccordionsCopy)
  }

  if (isLoading || isFetching) return <Loader />
  if (!taskArray) return <Text>No data found</Text>

  let displayedTasks = taskArray
  if (!showedTaskTypes.includes('are_repetitive_tasks_visible')) {
    displayedTasks = displayedTasks.filter((t) => t.task_type !== TaskTypeEnum.REPETITIVE_TASK)
  }
  if (!showedTaskTypes.includes('are_predifined_tasks_visible')) {
    displayedTasks = displayedTasks.filter((t) => t.task_type !== TaskTypeEnum.PREDEFINED_TASK)
  }
  if (!showedTaskTypes.includes('are_work_flow_tasks_visible')) {
    displayedTasks = displayedTasks.filter((t) => t.task_type !== TaskTypeEnum.WORK_FLOW_TASK)
  }

  const maxTime = Math.max(...taskArray.map((t) => t.duration_sec)) * 1.2
  const taskSizeMapper: { [key: string]: PercentageValue } = {}
  taskArray.forEach(
    (t) =>
      (taskSizeMapper[t.task_name] = Math.round(
        (t.duration_sec / maxTime) * 100,
      ) as PercentageValue),
  )

  return (
    <div className='flex flex-col gap-2 px-2'>
      <TaskListFilterMenu />
      <div className='overflow-hidden pb-2'>
        <div className='mb-1 flex w-full justify-end'>
          <Checkboxes
            options={[
              { label: 'Work flow tasks', value: 'are_work_flow_tasks_visible' },
              { label: 'Predefined tasks', value: 'are_predifined_tasks_visible' },
              { label: 'Repetitive tasks', value: 'are_repetitive_tasks_visible' },
            ]}
            values={showedTaskTypes}
            onChange={setShowedTaskTypes}
          />
        </div>
        {displayedTasks.map((task, i) => (
          <div
            key={`${task.task_name}-${i}-${task.duration_sec}-${task.task_type}`}
            className='mb-5 w-full rounded-xl bg-white p-4 shadow'
          >
            <TaskListItem
              task={task}
              progressBarSize={taskSizeMapper[task.task_name]}
              processMapper={processMapper}
              isAccordionOpen={openAccordions[i]}
              setIsAccordionOpen={() => updateOpenAccordions(i)}
            />
            <div className={openAccordions[i] ? '' : 'hidden'}>
              <div>
                <TaskListItemExtraContent
                  activityData={task.activity_categorizations}
                  variants={task.top_variants}
                  dataTransafers={task.top_data_transfers}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TaskListView
