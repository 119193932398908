import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { BriefcaseIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { useMemo } from 'react'

import { useGetTransactionActiveWorkTrendQuery } from '../../../app/apiSliceProcessApi'
import { InlineLoader } from '../../../common/components'
import { LinkButton, Text } from '../../../common/designs'
import { useEnhancedNavigate } from '../../../common/hooks'
import { MainPathEnum, SubPathEnum } from '../../../common/types/routing'
import { SubSearchParamEnum } from '../../../common/types/searchParams'
import { formatNumber } from '../../../common/utils/numberFormatting'
import { type ProcessGeneralInfo, TrendTimeGranularityEnum } from '../types'
import { yearAndWeekToKey } from './utils'

interface Props {
  process: ProcessGeneralInfo
  totalAppUsageTrendMapper: { [key: string]: number }
}

const TrackedProcessStat = ({ process, totalAppUsageTrendMapper }: Props) => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()
  const { data, isLoading } = useGetTransactionActiveWorkTrendQuery({
    processId: process.id,
    filters: {},
    trend_granularity: TrendTimeGranularityEnum.WEEK,
  })

  const [currentValue, prevWeekValue] = useMemo(() => {
    if (!data || data.length === 0) return [0, 0]

    const getWeeklyData = (index: number) => {
      if (!data || index < 0) return [0, 0]

      const { year, isoWeekNumber, total_duration_sec: totalProcessTime } = data[index]
      const totalAppUsageTime = totalAppUsageTrendMapper[yearAndWeekToKey(year, isoWeekNumber)] ?? 1
      return [totalProcessTime, totalAppUsageTime]
    }

    let curValue, prevValue
    if (data.length < 3) {
      const [totalProcessTime, totalAppUsageTime] = getWeeklyData(0)
      curValue = (totalProcessTime / totalAppUsageTime) * 100
      prevValue = 0
    } else {
      const [totalProcessTime, totalAppUsageTime] = getWeeklyData(data.length - 2)
      const [totalProcessTimePrev, totalAppUsageTimePrev] = getWeeklyData(data.length - 3)
      curValue = (totalProcessTime / totalAppUsageTime) * 100
      prevValue = (totalProcessTimePrev / totalAppUsageTimePrev) * 100
    }

    return [curValue, prevValue]
  }, [data, totalAppUsageTrendMapper])

  const value = currentValue ?? 0
  const prevValue = prevWeekValue ?? 0
  const processLink = `${MainPathEnum.PROCESSES}/${process.id}/${SubPathEnum.OVERVIEW}`
  const activeFilterId = process.active_filter_id

  return (
    <div className='relative w-[420px] overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6'>
      <Text className='mb-3' size='xl' weight={600}>
        {process.name}
      </Text>

      <dt>
        <div className='absolute rounded-md bg-indigo-500 p-3'>
          <BriefcaseIcon className='size-6 text-white' aria-hidden='true' />
        </div>
      </dt>

      <dd className='ml-16'>
        {isLoading ? (
          <InlineLoader />
        ) : (
          <>
            <p className='truncate text-sm font-medium text-gray-500'>
              Share of process work from total weekly time
            </p>

            <div className='flex items-center pb-6 sm:pb-7'>
              <p className='text-2xl font-semibold text-gray-900'>
                {formatNumber(Math.min(value, 100), 10)}%
              </p>
              <p
                className={clsx(
                  value - prevValue >= 0 ? 'text-green-600' : 'text-red-600',
                  'ml-2 flex items-baseline text-sm font-semibold',
                )}
              >
                {value - prevValue >= 0 ? (
                  <ArrowUpIcon
                    className='size-5 shrink-0 self-center text-green-500'
                    aria-hidden='true'
                  />
                ) : (
                  <ArrowDownIcon
                    className='size-5 shrink-0 self-center text-red-500'
                    aria-hidden='true'
                  />
                )}
                <span className='sr-only'>
                  {value - prevValue >= 0 ? 'Increased' : 'Decreased'} by{' '}
                </span>
                {formatNumber(Math.min(value - prevValue, 100))} %
              </p>
            </div>
          </>
        )}
        <div className='absolute inset-x-0 bottom-0 bg-gray-50 p-4 sm:px-6'>
          <div className='text-sm'>
            <LinkButton
              className='flex w-[120px] gap-2'
              onClick={() => {
                navigate(`../${processLink}`, {
                  search: `?${SubSearchParamEnum.PROCESS_FILTER}=${activeFilterId}`,
                })
              }}
            >
              View process
              <ArrowRightIcon className='size-5' />
            </LinkButton>
          </div>
        </div>
      </dd>
    </div>
  )
}

export default TrackedProcessStat
