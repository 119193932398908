import { useGetProcessStepUserActionAnalysisQuery } from '../../../../../app/apiSliceProcessApi'
import { useQueryFilters } from '../../../../../common/hooks'
import { UserActionTypeEnum } from '../../../types'
import { UserActivitiesActivityView } from '../../UserActivitiesActivity'

const StepUserActivitiesActivity = () => {
  const { processFiltersWithZooming: filters, processId } = useQueryFilters()
  const { data, isFetching } = useGetProcessStepUserActionAnalysisQuery({
    filters,
    processId,
    action_type: UserActionTypeEnum.CLICK_ACTION,
  })

  return <UserActivitiesActivityView data={data} isFetching={isFetching} />
}

export default StepUserActivitiesActivity
