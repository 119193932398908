import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, Resolver, useForm } from 'react-hook-form'

import useManageServerTestCasesAndConfigs from '../../../features/ConfigureDataCollectionAdminPanelPage/useManageServerTestCasesAndConfigs'
import type {
  RuleIn,
  WindowChangeTriggerRuleFormData,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { Button, InputField, Text, TextArea } from '../../designs'
import { windowChangeTriggerRuleFormSchema } from '../DataCollectionForms/formSchemas'
import { Loader } from '../Loader'
import { ContentDisplay } from '../displays'
import {
  windowChangeTriggerFormDataToRuleIn,
  windowChangeTriggerRuleToFormData,
} from './dataHelpers'

interface Props {
  ruleIn: RuleIn
}

const WindowChangeTriggerFields = ({ ruleIn }: Props) => {
  const { updateRules, deleteRule, isUploadingRules } = useManageServerTestCasesAndConfigs()

  const { control, handleSubmit } = useForm<WindowChangeTriggerRuleFormData>({
    resolver: yupResolver(
      windowChangeTriggerRuleFormSchema,
    ) as Resolver<WindowChangeTriggerRuleFormData>,
    defaultValues: windowChangeTriggerRuleToFormData(ruleIn),
  })

  const onUpdateWindowChangeTrigger = async (formData: WindowChangeTriggerRuleFormData) => {
    const updatedRule = windowChangeTriggerFormDataToRuleIn(formData, ruleIn)
    await updateRules([updatedRule])
  }

  const onDeleteWindowChangeTrigger = async () => {
    await deleteRule(ruleIn.id)
  }

  if (isUploadingRules) return <Loader />

  return (
    <ContentDisplay className='p-4'>
      <Text size='l' weight={500}>
        {ruleIn.dashboard_context?.window_change_trigger_name}
      </Text>
      <form className='mt-1 space-y-3' onSubmit={handleSubmit(onUpdateWindowChangeTrigger)}>
        <Controller
          name='triggerName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <InputField
              size='xs'
              label='Trigger Name'
              autoComplete='off'
              error={error?.message}
              {...field}
            />
          )}
        />

        <Controller
          name='reaction'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextArea
              rows={10}
              label='Reaction Pattern'
              autoComplete='off'
              error={error?.message}
              {...field}
            />
          )}
        />

        <div className='flex gap-3'>
          <Button type='submit'>Update</Button>
          <Button variant='destructive' onClick={onDeleteWindowChangeTrigger}>
            Delete
          </Button>
        </div>
      </form>
    </ContentDisplay>
  )
}

export default WindowChangeTriggerFields
