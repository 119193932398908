import type { SelectOption } from '../common/designs'

export const maxInteger = 2147483647
export const defaultUserRole = 3 // data-reader
export const DEFAULT_WEEK_START_ON = 1 // Monday
export const USE_CURRENCY_SYMBOL_UNIT = 'CurrencySymbol'

export const STORAGE_ACCOUNT = {
  OPPORTUNITIES: {
    CONTAINER_NAME: 'opportunities',
    FOLDER_PATH: 'images',
  },
}

export const TAGS_OF_INTEREST = {
  application: ['appname'],
  window: ['appname', 'content-category'],
}

export const MAX_FILE_UPLOAD_SIZE = 10 * 1000 * 1000 // 10 MB
export const JSON_FILE_TYPE = 'application/json'
export const MAX_ZIP_FILE_UPLOAD_SIZE = 100 * 1000 * 1000 // 100 MB
export const ZIP_FILE_TYPE =
  'zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'

export const currencyDropdownNew: SelectOption[] = [
  { value: 'EUR', label: 'EUR - €' },
  { value: 'USD', label: 'USD - $' },
  { value: 'GBP', label: 'GBP - £' },
]

export const currencyDropdown: SelectOption[] = [
  { value: 'EUR', label: 'EUR - €' },
  { value: 'USD', label: 'USD - $' },
  { value: 'GBP', label: 'GBP - £' },
]

export const currencyMapper: any = {
  EUR: {
    symbol: '€',
    symbolUTF8: '8364',
  },
  USD: {
    symbol: '$',
    symbolUTF8: '36',
  },
  GBP: {
    symbol: '£',
    symbolUTF8: '163',
  },
}

export enum STATUS {
  // Successful - 2xx
  // This class of status code indicates that the client's request was successfully received, understood, and accepted.
  HTTP_200_OK = 200,
  HTTP_201_CREATED = 201,
  HTTP_202_ACCEPTED = 202,
  HTTP_203_NON_AUTHORITATIVE_INFORMATION = 203,
  HTTP_204_NO_CONTENT = 204,
  HTTP_205_RESET_CONTENT = 205,
  HTTP_206_PARTIAL_CONTENT = 206,
  HTTP_207_MULTI_STATUS = 207,
  HTTP_208_ALREADY_REPORTED = 208,
  HTTP_226_IM_USED = 226,

  // Redirection - 3xx
  // This class of status code indicates that further action needs to be taken by the user agent in order to fulfill the request.
  HTTP_300_MULTIPLE_CHOICES = 300,
  HTTP_301_MOVED_PERMANENTLY = 301,
  HTTP_302_FOUND = 302,
  HTTP_303_SEE_OTHER = 303,
  HTTP_304_NOT_MODIFIED = 304,
  HTTP_305_USE_PROXY = 305,
  HTTP_306_RESERVED = 306,
  HTTP_307_TEMPORARY_REDIRECT = 307,
  HTTP_308_PERMANENT_REDIRECT = 308,

  // Client Error - 4xx
  // The 4xx class of status code is intended for cases in which the client seems to have erred. Except when responding to a HEAD request, the server SHOULD include an entity containing an explanation of the error situation, and whether it is a temporary or permanent condition.
  HTTP_400_BAD_REQUEST = 400,
  HTTP_401_UNAUTHORIZED = 401,
  HTTP_402_PAYMENT_REQUIRED = 402,
  HTTP_403_FORBIDDEN = 403,
  HTTP_404_NOT_FOUND = 404,
  HTTP_405_METHOD_NOT_ALLOWED = 405,
  HTTP_406_NOT_ACCEPTABLE = 406,
  HTTP_407_PROXY_AUTHENTICATION_REQUIRED = 407,
  HTTP_408_REQUEST_TIMEOUT = 408,
  HTTP_409_CONFLICT = 409,
  HTTP_410_GONE = 410,
  HTTP_411_LENGTH_REQUIRED = 411,
  HTTP_412_PRECONDITION_FAILED = 412,
  HTTP_413_REQUEST_ENTITY_TOO_LARGE = 413,
  HTTP_414_REQUEST_URI_TOO_LONG = 414,
  HTTP_415_UNSUPPORTED_MEDIA_TYPE = 415,
  HTTP_416_REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  HTTP_417_EXPECTATION_FAILED = 417,
  HTTP_422_UNPROCESSABLE_ENTITY = 422,
  HTTP_423_LOCKED = 423,
  HTTP_424_FAILED_DEPENDENCY = 424,
  HTTP_426_UPGRADE_REQUIRED = 426,
  HTTP_428_PRECONDITION_REQUIRED = 428,
  HTTP_429_TOO_MANY_REQUESTS = 429,
  HTTP_431_REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
  HTTP_451_UNAVAILABLE_FOR_LEGAL_REASONS = 451,

  // Server Error - 5xx
  // Response status codes beginning with the digit "5" indicate cases in which the server is aware that it has erred or is incapable of performing the request. Except when responding to a HEAD request, the server SHOULD include an entity containing an explanation of the error situation, and whether it is a temporary or permanent condition.
  HTTP_500_INTERNAL_SERVER_ERROR = 500,
  HTTP_501_NOT_IMPLEMENTED = 501,
  HTTP_502_BAD_GATEWAY = 502,
  HTTP_503_SERVICE_UNAVAILABLE = 503,
  HTTP_504_GATEWAY_TIMEOUT = 504,
  HTTP_505_HTTP_VERSION_NOT_SUPPORTED = 505,
  HTTP_506_VARIANT_ALSO_NEGOTIATES = 506,
  HTTP_507_INSUFFICIENT_STORAGE = 507,
  HTTP_508_LOOP_DETECTED = 508,
  HTTP_509_BANDWIDTH_LIMIT_EXCEEDED = 509,
  HTTP_510_NOT_EXTENDED = 510,
  HTTP_511_NETWORK_AUTHENTICATION_REQUIRED = 511,
}

export const GITBOOK_DOCUMENTATOIN_BASE_URL = 'https://processmaker.gitbook.io/process-intelligence'
export const LINK_DEVELOPER_DOCUMENTATION = `${GITBOOK_DOCUMENTATOIN_BASE_URL}/developer-documentation`
export const LINK_USER_DOCUMENTATION = GITBOOK_DOCUMENTATOIN_BASE_URL
export const LINK_SUPPORT = 'https://support.processmaker.com/'
export const LINK_COPMANY_HOME_PAGE = 'https://www.workfellow.com'

export const DEFAULT_DATE_FORMATTING_LOCALE = 'en-US'
