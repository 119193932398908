import { XMarkIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { useState } from 'react'
import { toast } from 'react-toastify'

import { useRunXRayPatternTestsMutation } from '../../app/apiSlice'
import { JSON_FILE_TYPE } from '../../app/constants'
import { ContentDisplay, Loader } from '../../common/components'
import { Button, FileSelector, IconButton, InputField, Text, TextArea } from '../../common/designs'
import type { JsonObject } from '../../common/types/common'
import { isRawPatternType } from '../../common/types/typeGuards'
import { readUploadedFileAsText, validateFile } from '../../common/utils/fileUtils'
import type { PatternXrayTestBody, RawPatternType, XRayPatternTestCaseRenderData } from './types'

const XRayPatternTester = () => {
  const [runXRayPatternTests, { isLoading: isRunningTests }] = useRunXRayPatternTestsMutation()

  const [patternValue, setPatterValue] = useState<string>('')
  const [patternError, setPatternError] = useState<string | null>(null)
  const [testCases, setTestCases] = useState<XRayPatternTestCaseRenderData[]>([])

  const onChangePatternValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPatterValue(e.target.value)
    setPatternError(null)
  }

  const onAddTestCase = () => {
    setTestCases([
      ...testCases,
      {
        xRayJson: null,
        expectedValue: '',
        formFileError: null,
        formExpectedValueError: null,
        testResultError: null,
        isTestedSuccesfully: false,
      },
    ])
  }

  const onDeleteTestCase = (i: number) => {
    const updatedTestCases = [...testCases]
    updatedTestCases.splice(i, 1)
    setTestCases(updatedTestCases)
  }

  const onFileChange = async (files: File[], i: number) => {
    const { file, error } = validateFile(files[0], JSON_FILE_TYPE)
    const xRayJson = error || !file ? null : ((await readUploadedFileAsText(file)) as JsonObject)

    setTestCases([
      ...testCases.slice(0, i),
      {
        ...testCases[i],
        xRayJson,
        formFileError: error ?? null,
        testResultError: null,
        isTestedSuccesfully: false,
      },
      ...testCases.slice(i + 1),
    ])
  }

  const onChangeExpectedValue = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const expectedValue = e.target.value
    setTestCases([
      ...testCases.slice(0, i),
      {
        ...testCases[i],
        expectedValue,
        formExpectedValueError: null,
        testResultError: null,
        isTestedSuccesfully: false,
      },
      ...testCases.slice(i + 1),
    ])
  }

  const onSendToTesting = async () => {
    try {
      const data = JSON.parse(patternValue)
      if (!isRawPatternType(data)) {
        setPatternError('Invalid Reaction')
        toast.error('Fix Errors')
        return
      }
    } catch {
      setPatternError('Invalid Reaction')
      toast.error('Fix Errors')
      return
    }

    setExpectedValueErrors()

    const hasErrorsFormErrors = testCases.some(
      ({ formExpectedValueError, formFileError }) => formExpectedValueError || formFileError,
    )

    if (hasErrorsFormErrors) {
      toast.error('Fix Errors')
      return
    }

    const requestBody = {
      pattern: JSON.parse(patternValue) as RawPatternType,
      x_ray_test_cases: testCases.map(({ xRayJson, expectedValue }) => ({
        x_ray_dict: xRayJson,
        expected_value: expectedValue,
      })),
    } as PatternXrayTestBody

    runXRayPatternTests(requestBody)
      .unwrap()
      .then((res) => {
        let passedTests = 0
        let failedTests = 0
        res.forEach(({ is_passed }) => {
          is_passed ? (passedTests += 1) : (failedTests += 1)
        })

        const toastMessage = `Test Run completed. Passed Tests: ${passedTests}, Failing Tests: ${failedTests}`
        failedTests === 0 ? toast.success(toastMessage) : toast.error(toastMessage)
        setTestCases(
          testCases.map((testCase, i) => ({
            ...testCase,
            testResultError: res[i].error_message,
            isTestedSuccesfully: res[i].is_passed,
          })),
        )
      })
      .catch((e) => {
        toast.error(`Error running the tests: ${e}`)
      })
  }

  const setExpectedValueErrors = () => {
    setTestCases(
      testCases.map((testCase) => ({
        ...testCase,
        formExpectedValueError: testCase.expectedValue.length === 0 ? 'Value Required' : null,
      })),
    )
  }

  return (
    <>
      {isRunningTests && <Loader />}
      <div className={clsx('space-y-3', isRunningTests ? 'hidden' : '')}>
        <Text size='xl' weight={600}>
          Test pattern against X-Ray files
        </Text>

        <TextArea
          label='Reaction'
          rows={10}
          value={patternValue}
          onChange={onChangePatternValue}
          placeholder='[{"Go": "Foreground"}, {"Go": {"Child": 0}}, {"Extract": {"Property": "Name", "As": "Name"}}]'
          error={patternError ?? undefined}
        />

        {testCases.map((testCase, i) => (
          <ContentDisplay key={i}>
            <div className='mb-1 flex w-full justify-end'>
              <IconButton
                icon={<XMarkIcon />}
                variant='ghost'
                onClick={() => onDeleteTestCase(i)}
              />
            </div>
            <div className='space-y-2 px-4 pb-4'>
              <FileSelector
                onChange={(file: File[]) => onFileChange(file, i)}
                accept={JSON_FILE_TYPE}
                error={testCase.formFileError ?? undefined}
              />
              <InputField
                label='Expected Value'
                value={testCase.expectedValue}
                onChange={(e) => onChangeExpectedValue(e, i)}
                error={testCase.formExpectedValueError ?? undefined}
                autoComplete='off'
              />
              {testCase.testResultError && <Text color='error'>{testCase.testResultError}</Text>}
              {testCase.isTestedSuccesfully && <Text className='text-green-400'>Test Passed</Text>}
            </div>
          </ContentDisplay>
        ))}
        <div className='flex gap-3'>
          <Button onClick={onAddTestCase}>Add new Test</Button>
          <Button onClick={onSendToTesting}>Run Tests</Button>
        </div>
      </div>
    </>
  )
}

export default XRayPatternTester
