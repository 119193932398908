import { useRoutes } from 'react-router-dom'

import AdvancedSetup from './AdvancedSetup'
import ConfigFileManagement from './ConfigFileManagement'
import RequirementFileManagement from './RequirementFileManagement'
import XRayPatternTester from './XRayPatternTester'
import XRayTools from './XRayTools'
import { AdvancedSetupSubPathEnum } from './types'

const AdvancedSetupRouting = () => {
  return useRoutes([
    { index: true, element: <AdvancedSetup />, id: 'admin_advanced_setup' },
    {
      path: AdvancedSetupSubPathEnum.CONFIG_FILE_MANAGEMENT,
      element: <ConfigFileManagement />,
      id: 'admin_config_file_management',
    },
    {
      path: AdvancedSetupSubPathEnum.REQUIREMENT_FILE_MANAGEMENT,
      element: <RequirementFileManagement />,
      id: 'admin_requirement_file_management',
    },
    {
      path: AdvancedSetupSubPathEnum.X_RAY_TOOLS,
      element: <XRayTools />,
      id: 'admin_x_ray_tools',
    },
    {
      path: AdvancedSetupSubPathEnum.X_RAY_PATTERN_TESTER,
      element: <XRayPatternTester />,
      id: 'admin_x_ray_pattern_tester',
    },
  ])
}

export default AdvancedSetupRouting
