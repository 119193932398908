import {
  CloudArrowUpIcon,
  DocumentMagnifyingGlassIcon,
  FolderArrowDownIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import {
  useGetDashboardAppDataQuery,
  useGetDataCollectionConfigsQuery,
  useGetToolingLinkQuery,
  useGetXrayZipFileListQuery,
  useLazyGetXRayZipFileDownloadLinkQuery,
  useUploadXrayZipFileMutation,
} from '../../app/apiSlice'
import {
  GITBOOK_DOCUMENTATOIN_BASE_URL,
  MAX_ZIP_FILE_UPLOAD_SIZE,
  ZIP_FILE_TYPE,
} from '../../app/constants'
import { InlineLoader, Loader, UserFlowStepContainer } from '../../common/components'
import type { AppTestCaseGroup } from '../../common/components/ApplicationConfigPage/types'
import { Breadcrumbs, Button, Divider, FileSelector, Link, Table, Text } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import { downloadFile } from '../../common/utils/utils'
import { getAppListFromTestCases } from '../ConfigureDataCollectionAdminPanelPage/dataHelpers'
import AddReactionModal from './XRayAddReactionModal'

const LINK_X_RAY_CAPTURING_TOOL = `${GITBOOK_DOCUMENTATOIN_BASE_URL}/data-collection/data-collection-with-x-ray#capturing-the-ui-blueprint-with-x-ray-recorder-and-uploading-to-the-platform`
const LINK_X_RAY_FOLDER = `${GITBOOK_DOCUMENTATOIN_BASE_URL}/data-collection/data-collection-with-x-ray#uploading-x-rays`
const LINK_X_RAY_DESKTOP_TOOL = `${GITBOOK_DOCUMENTATOIN_BASE_URL}/data-collection/data-collection-with-x-ray#downloading-and-analyzing-the-ui-blueprints-with-the-x-ray-viewer`

const XRayTools = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [selectedApplication, setSelectedApplication] = useState<AppTestCaseGroup | null>(null)

  const { data: applicationMetaData, isLoading: isLoadingApplicationMetaData } =
    useGetDashboardAppDataQuery()
  const { data: ruleData, isLoading: isLoadingRuleData } = useGetDataCollectionConfigsQuery()

  const [uploadXrayZipFile, { isLoading: isLoadingUploadFile }] = useUploadXrayZipFileMutation()
  const { data: xRayList, isLoading: isLoadingXRayList } = useGetXrayZipFileListQuery()
  const [getDownloadLink, { isLoading: isLoadingGetDownloadLink }] =
    useLazyGetXRayZipFileDownloadLinkQuery()
  const { data: toolingLink } = useGetToolingLinkQuery()

  const appList = useMemo(() => {
    const { appData } = getAppListFromTestCases(
      [],
      applicationMetaData ?? [],
      ruleData?.rules ?? [],
      null,
    )

    return appData.sort((a, b) => a.appName.localeCompare(b.appName))
  }, [applicationMetaData, ruleData])

  if (isLoadingApplicationMetaData || isLoadingRuleData) return <Loader />

  const onFileChange = (files: File[]): number | string | void => {
    if (!files.length) return

    if (files[0].size > MAX_ZIP_FILE_UPLOAD_SIZE) {
      return toast.warning(`File size exceed limit of ${MAX_ZIP_FILE_UPLOAD_SIZE / 1000 / 1000} MB`)
    }
    setUploadedFile(files[0])
  }

  const onUploadFile = async () => {
    if (!uploadedFile) return

    const formData = new FormData()
    formData.append('zip_file', uploadedFile)
    uploadXrayZipFile(formData)
      .unwrap()
      .then(() => {
        toast.success('X-Rays uploaded successfully')
      })
      .catch(() => {
        toast.error('Something went wrong when uploading the X-Rays')
      })
  }

  const onDownloadFile = async (fileName: string) => {
    const res = await getDownloadLink({ zip_name: fileName })
    if (res.data) downloadFile(res.data, fileName)
  }

  return (
    <>
      <Breadcrumbs
        variant='secondary'
        items={[
          {
            label: 'Configure Data Collection',
            onClick: () => navigate('../..'),
          },
          {
            label: 'Advanced Setup',
            onClick: () => navigate('..'),
          },
          {
            label: 'X-Ray Tooling',
          },
        ]}
      />

      <Divider className='my-4' color='lightGray' />
      <div>
        <UserFlowStepContainer
          stepNumber={1}
          title='Download Process Intelligence Tooling'
          description='Tooling includes X-Ray capturing and desktop tools used to collect and work with the X-Rays.'
          icon={<WrenchIcon />}
        >
          <div>
            <Button
              className='shrink-0'
              size='s'
              iconStart={<ArrowDownTrayIcon />}
              onClick={() => {
                downloadFile(toolingLink ?? '', 'process-intelligence-tool-bundle.zip')
              }}
              disabled={!toolingLink}
            >
              Download Tool Bundle
            </Button>

            <div className='mt-2'>
              <Link href={LINK_X_RAY_CAPTURING_TOOL} size='s' target='_blank'>
                Instructions: How to use the X-Ray capturing tool?
              </Link>
            </div>
          </div>
        </UserFlowStepContainer>

        <Divider />

        <UserFlowStepContainer
          stepNumber={2}
          title='Upload Captured X-Rays'
          description='Create a ZIP file from the X-Ray folder and upload it here for later use.'
          icon={<CloudArrowUpIcon />}
        >
          <div className='gap-2 lg:flex'>
            <FileSelector size='s' onChange={onFileChange} accept={ZIP_FILE_TYPE} />
            <Button
              size='s'
              onClick={onUploadFile}
              disabled={!uploadedFile}
              loading={isLoadingUploadFile}
            >
              Upload X-Ray ZIP file
            </Button>
          </div>
          <div className='mt-2'>
            <Link href={LINK_X_RAY_FOLDER} size='s' target='_blank'>
              Instructions: Where to find the X-Ray folder?
            </Link>
          </div>
        </UserFlowStepContainer>

        <Divider />

        <UserFlowStepContainer
          stepNumber={3}
          title='Download Captured X-Rays'
          description='Download X-Ray ZIP files. You can then open the X-Ray files in the X-Ray desktop tool to work on them.'
          icon={<FolderArrowDownIcon />}
        >
          {isLoadingXRayList ? (
            <InlineLoader />
          ) : (
            [...(xRayList ?? [])]
              .sort((a, b) => a.localeCompare(b))
              .map((name) => (
                <div key={name} className='mb-2 items-center gap-0 lg:flex'>
                  <Text
                    className='-mr-2 rounded-md border border-r-0 border-gray-500 bg-gray-200 py-1 pl-4 pr-6'
                    size='s'
                  >
                    {name}
                  </Text>
                  <Button
                    size='xs'
                    onClick={() => onDownloadFile(name)}
                    loading={isLoadingGetDownloadLink}
                  >
                    Download X-Ray ZIP file
                  </Button>
                </div>
              ))
          )}
          <div className='mt-2'>
            <Link href={LINK_X_RAY_DESKTOP_TOOL} size='s' target='_blank'>
              Instructions: How to use the X-Ray desktop tool?
            </Link>
          </div>
        </UserFlowStepContainer>

        <Divider />

        <UserFlowStepContainer
          stepNumber={4}
          title='Add reactions for Application Windows'
          description='Copy and paste the reactions from the desktop tool to selected windows.'
          icon={<DocumentMagnifyingGlassIcon />}
        >
          <div className='-ml-4 max-w-[1000px]'>
            <Table
              head={['App Name', '']}
              body={[
                ...(appList ?? []).map((app) => {
                  return [
                    app.appName,
                    <Button key={app.appName} onClick={() => setSelectedApplication(app)}>
                      Add reactions
                    </Button>,
                  ]
                }),
              ]}
            />
          </div>
        </UserFlowStepContainer>
      </div>

      <AddReactionModal
        selectedApplication={selectedApplication}
        onClose={() => setSelectedApplication(null)}
      />
    </>
  )
}

export default XRayTools
