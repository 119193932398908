import { useEffect, useState } from 'react'

import { useLazyGetTransactionStepGroupMultipleQuery } from '../../../../app/apiSliceProcessApi'
import { DEFAULT_DATE_FORMATTING_LOCALE } from '../../../../app/constants'
import { BarChartAndTableDisplay, InlineLoader } from '../../../../common/components'
import type { ChartDataEntry } from '../../../../common/components/Charts/types'
import { Select } from '../../../../common/designs'
import { useQueryFilters } from '../../../../common/hooks'
import { getBestTimeFormatTransformationForSeconds } from '../../../../common/utils/numberFormatting'
import { GroupByFieldTypeEnum, MetricNamesEnum } from '../../types'
import { TREND_DATE_FORMAT_OPTIONS } from '../TrendConstants'

const TIME_SERIES_DATE_OPTIONS = [
  { label: 'By Case End Date', value: GroupByFieldTypeEnum.END_DATE },
  { label: 'By Case Start Date', value: GroupByFieldTypeEnum.START_DATE },
]

export const TransactionTimeSeriesAnalysis = () => {
  const { processId, processFilters: filters } = useQueryFilters()

  const [selectedDateOption, setSelectedDateOption] = useState<GroupByFieldTypeEnum>(
    GroupByFieldTypeEnum.END_DATE,
  )
  const [chartData, setChartData] = useState<ChartDataEntry[]>([])
  const [chartUnit, setChartUnit] = useState<string>('seconds')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [getTransactionStepGroupMultiple] = useLazyGetTransactionStepGroupMultipleQuery()

  useEffect(() => {
    fetchData()
  }, [selectedDateOption]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    setIsLoading(true)
    const { data, error } = await getTransactionStepGroupMultiple({
      processId,
      filters,
      group_by: [
        { field_type: GroupByFieldTypeEnum.TRANSACTION_ID },
        { field_type: selectedDateOption },
      ],
      metrics: [MetricNamesEnum.THROUGHPUT_TIME],
    })

    if (error || !data) return setIsLoading(false)

    const throughputTimeData = data[MetricNamesEnum.THROUGHPUT_TIME]

    const { divider, unit } = getBestTimeFormatTransformationForSeconds(
      throughputTimeData.length ? throughputTimeData[0].value : 0,
    )

    const timeSeriesAnalysisData = throughputTimeData.map((r) => ({
      throughputTime: r.value / divider,
      date:
        new Date(
          r.labels.find((l) => l.label_type === GroupByFieldTypeEnum.END_DATE)
            ?.label_value as string,
        ) ?? new Date(),
      transactionId:
        (r.labels.find((l) => l.label_type === GroupByFieldTypeEnum.TRANSACTION_ID)
          ?.label_value as string) ?? '',
    }))
    timeSeriesAnalysisData.sort(
      (a, b) =>
        a.date.getTime() - b.date.getTime() || a.transactionId.localeCompare(b.transactionId),
    )
    setChartUnit(unit)
    setChartData(
      timeSeriesAnalysisData.map(({ date, transactionId, throughputTime }) => ({
        label: `${date.toLocaleString(
          DEFAULT_DATE_FORMATTING_LOCALE,
          TREND_DATE_FORMAT_OPTIONS,
        )} - Case I ${transactionId}`,
        value: throughputTime,
      })),
    )

    setIsLoading(false)
  }

  return (
    <div>
      {isLoading ? (
        <InlineLoader />
      ) : (
        <BarChartAndTableDisplay
          label='Case Time-Series Analysis'
          data={chartData}
          unit={chartUnit}
          tableHeaderValues={['Date + Case ID', 'Duration']}
          isExportable
          maxXLabelLength={40}
          height='350px'
          selectActions={[
            <Select
              key='selectDateOption'
              options={TIME_SERIES_DATE_OPTIONS}
              value={selectedDateOption}
              onChange={setSelectedDateOption as React.Dispatch<React.SetStateAction<string>>}
              size='xs'
            />,
          ]}
          isAllDataVisible
        />
      )}
    </div>
  )
}

export default TransactionTimeSeriesAnalysis
