import { useGetProcessCopyPasteAnalysisQuery } from '../../../app/apiSliceProcessApi'
import { Loader } from '../../../common/components'
import { useQueryFilters } from '../../../common/hooks'
import { VariableLabelEnum } from '../types'
import type { UserActivitiesData } from '../types'
import UserActivitiesBarchart from './UserActivitiesBarchart'

const CHECKBOX_OPTIONS = [
  {
    label: VariableLabelEnum.SOURCE_WINDOW,
    value: VariableLabelEnum.SOURCE_WINDOW,
  },
  {
    label: VariableLabelEnum.TARGET_WINDOW,
    value: VariableLabelEnum.TARGET_WINDOW,
  },
  {
    label: VariableLabelEnum.SOURCE_FIELD,
    value: VariableLabelEnum.SOURCE_FIELD,
  },
  {
    label: VariableLabelEnum.TARGET_FIELD,
    value: VariableLabelEnum.TARGET_FIELD,
  },
  {
    label: VariableLabelEnum.CONTENT,
    value: VariableLabelEnum.CONTENT,
  },
]

const DEFAULT_SELECTED_VALUES = [
  VariableLabelEnum.SOURCE_WINDOW,
  VariableLabelEnum.TARGET_WINDOW,
  VariableLabelEnum.SOURCE_FIELD,
  VariableLabelEnum.TARGET_FIELD,
  VariableLabelEnum.CONTENT,
]

interface Props {
  data: UserActivitiesData[] | undefined
  isFetching: boolean
}

export const UserActivitiesCopyPastesView = ({ data, isFetching }: Props) => {
  if (isFetching) return <Loader />

  return (
    <UserActivitiesBarchart
      checkboxOptions={CHECKBOX_OPTIONS}
      defaultSelectedValues={DEFAULT_SELECTED_VALUES}
      data={data ?? []}
    />
  )
}

const UserActivitiesCopyPastes = () => {
  const { processFilters: filters, processId } = useQueryFilters()
  const { data, isFetching } = useGetProcessCopyPasteAnalysisQuery({ filters, processId })

  return <UserActivitiesCopyPastesView data={data} isFetching={isFetching} />
}

export default UserActivitiesCopyPastes
