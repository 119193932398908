import { PlusIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'

import {
  useCreateConformanceFlowMutation,
  useDeleteConformanceFlowMutation,
  useEditConformanceFlowMutation,
  useGetConformanceFlowsQuery,
} from '../../../app/apiSlice'
import { useGetProcessConformanceMetricsByVariantQuery } from '../../../app/apiSliceProcessApi'
import { InlineLoader } from '../../../common/components'
import { Button } from '../../../common/designs'
import { useDashboard, useQueryFilters } from '../../../common/hooks'
import type { ConformanceFlowsData, ConformanceModalData } from '../types'
import { ConformanceTypeEnum } from '../types'
import ConformanceFlowAddEditModal from './ConformanceFlowAddEditModal'
import ConformanceFlowListItem from './ConformanceFlowListItem'

interface Props {
  selectedConformanceType: ConformanceTypeEnum
}

const ConformanceFlowList = ({ selectedConformanceType }: Props) => {
  const { user } = useDashboard()
  const { processFilters: filters, processId } = useQueryFilters()

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [selectedEditFlow, setSelectedEditFlow] = useState<ConformanceFlowsData | null>(null)

  const { data: conformanceFlowList, isFetching: isFetchingConformanceFlowList } =
    useGetConformanceFlowsQuery({
      processId,
      conformanceType: selectedConformanceType,
    })

  const { data: variantConformanceMetrics, isFetching: isFetchingVariantConformanceMetrics } =
    useGetProcessConformanceMetricsByVariantQuery(
      {
        processId,
        filters,
        conformance_flows: (conformanceFlowList ?? []).map((c) => c.steps),
        conformance_type: selectedConformanceType,
      },
      {
        skip: isFetchingConformanceFlowList,
      },
    )
  const [deleteConformanceFlow] = useDeleteConformanceFlowMutation()
  const [saveConformanceFlow] = useCreateConformanceFlowMutation()
  const [editConformanceFlow] = useEditConformanceFlowMutation()

  const onOpenModal = (editFlow: ConformanceFlowsData | null) => {
    setIsAddModalOpen(true)
    setSelectedEditFlow(editFlow)
  }

  const onCloseModal = () => {
    setIsAddModalOpen(false)
    setSelectedEditFlow(null)
  }

  const onSaveNewConformanceFlow = async (data: ConformanceModalData) => {
    await saveConformanceFlow({
      processId,
      body: { ...data, conformance_type: selectedConformanceType },
    })
    onCloseModal()
  }

  const onEditConformanceFlow = async (data: ConformanceModalData, conformanceId: string) => {
    await editConformanceFlow({
      processId,
      conformanceId,
      body: { ...data, conformance_type: selectedConformanceType },
    })
    onCloseModal()
  }

  const onDeleteConformanceFlow = async (conformanceId: string) => {
    await deleteConformanceFlow({
      processId,
      conformanceId,
    })
  }

  if (isFetchingConformanceFlowList) return <InlineLoader />

  return (
    <>
      <div>
        <div className='mb-3 flex flex-col gap-6'>
          {conformanceFlowList?.map((data) => (
            <ConformanceFlowListItem
              key={data.conformance_name}
              data={data}
              variantConformanceMetrics={variantConformanceMetrics}
              onOpenModal={onOpenModal}
              onDeleteConformanceFlow={onDeleteConformanceFlow}
              isFetchingMetrics={isFetchingVariantConformanceMetrics}
            />
          ))}
        </div>
        <Button
          size='s'
          iconStart={<PlusIcon />}
          onClick={() => onOpenModal(null)}
          disabled={!user.isAdmin}
        >
          Add new
        </Button>
      </div>

      {conformanceFlowList && (
        <ConformanceFlowAddEditModal
          isOpen={isAddModalOpen}
          selectedConformanceType={selectedConformanceType}
          onClose={onCloseModal}
          onSave={onSaveNewConformanceFlow}
          onEdit={onEditConformanceFlow}
          editData={selectedEditFlow}
        />
      )}
    </>
  )
}

export default ConformanceFlowList
