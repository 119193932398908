import type { ConfigTestCase } from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import type {
  DataCollectionFromUIElementRuleFormData,
  DataCollectionRuleFormData,
  DataCollectionTestCaseFormData,
  RuleIn,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { Text } from '../../designs'
import { ContentDisplay } from '../displays'
import DataCollectionRules from './DataCollectionRules'
import DataCollectionTests from './DataCollectionTests'
import type { TestCaseWithTestResult } from './types'

interface Props {
  windowName: string
  testCases: TestCaseWithTestResult[]
  rulesForTestCases: RuleIn[]
  uiFieldNames: string[]
  onAddTestCase: (formValues: DataCollectionTestCaseFormData, windowName: string) => Promise<void>
  onUpdateTestCase: (
    data: DataCollectionTestCaseFormData,
    configTestCase: ConfigTestCase,
  ) => Promise<void>
  onDeleteTestCase: (testCase: ConfigTestCase) => Promise<void>
  onAddRuleIn: (
    formValues: DataCollectionRuleFormData,
    uiFieldValues: DataCollectionFromUIElementRuleFormData,
    windowName: string,
  ) => Promise<void>
  onUpdateRuleIn: (
    formData: DataCollectionRuleFormData,
    originalRule: RuleIn,
    uiFieldValues: DataCollectionFromUIElementRuleFormData,
  ) => Promise<void>
  onUpdateRuleInCode: (newRule: RuleIn) => Promise<void>
  onDeleteRuleIn: (rule: RuleIn) => Promise<void>
}

const WindowDisplay = ({
  windowName,
  testCases,
  rulesForTestCases,
  uiFieldNames,
  onAddTestCase,
  onUpdateTestCase,
  onDeleteTestCase,
  onAddRuleIn,
  onUpdateRuleIn,
  onUpdateRuleInCode,
  onDeleteRuleIn,
}: Props) => {
  return (
    <ContentDisplay className='p-4'>
      <Text size='l' weight={500}>
        {windowName}
      </Text>

      <div className='w-full md:grid md:grid-cols-2 md:gap-4'>
        <DataCollectionTests
          testCases={testCases}
          uiFieldNames={uiFieldNames}
          onAddTestCase={(formValues: DataCollectionTestCaseFormData) =>
            onAddTestCase(formValues, windowName)
          }
          onUpdateTestCase={onUpdateTestCase}
          onDeleteTestCase={onDeleteTestCase}
        />
        <DataCollectionRules
          rulesForTestCases={rulesForTestCases}
          uiFieldNames={uiFieldNames}
          onAddRuleIn={(
            formValues: DataCollectionRuleFormData,
            uiFieldValues: DataCollectionFromUIElementRuleFormData,
          ) => onAddRuleIn(formValues, uiFieldValues, windowName)}
          onUpdateRuleIn={onUpdateRuleIn}
          onUpdateRuleInCode={onUpdateRuleInCode}
          onDeleteRuleIn={onDeleteRuleIn}
        />
      </div>
    </ContentDisplay>
  )
}

export default WindowDisplay
