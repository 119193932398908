import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Controller, Resolver, useForm } from 'react-hook-form'
import * as yup from 'yup'

import type { ConfigTestCase } from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import type {
  DataCollectionFromUIElementRuleFormData,
  DataCollectionRuleFormData,
  DataCollectionTestCaseFormData,
  RuleIn,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { Button, InputField, Link, Text } from '../../designs'
import AddWindowChangeTriggerFields from './AddWindowChangeTriggerFields'
import WindowChangeTriggerFields from './WindowChangeTriggerFields'
import WindowDisplay from './WindowDisplay'
import type { AppTestCaseGroup } from './types'

interface Props {
  appTestCasesGroup: AppTestCaseGroup
  onAddNewWindow: (windowName: string) => void
  onAddTestCase: (formValues: DataCollectionTestCaseFormData, windowName: string) => Promise<void>
  onUpdateTestCase: (
    data: DataCollectionTestCaseFormData,
    configTestCase: ConfigTestCase,
  ) => Promise<void>
  onDeleteTestCase: (testCase: ConfigTestCase) => Promise<void>
  onAddRuleIn: (
    formValues: DataCollectionRuleFormData,
    uiFieldValues: DataCollectionFromUIElementRuleFormData,
    windowName: string,
  ) => Promise<void>
  onUpdateRuleIn: (formData: DataCollectionRuleFormData, originalRule: RuleIn) => Promise<void>
  onUpdateRuleInCode: (newRule: RuleIn) => Promise<void>
  onDeleteRuleIn: (rule: RuleIn) => Promise<void>
}

interface AddNewWindowFormData {
  windowName: string
}

const addNewWindowFormSchema = yup
  .object({
    windowName: yup
      .string()
      .max(128, 'Window Name cannot be more than 128 characters long')
      .required('Window Name is a required field'),
  })
  .required()

const WindowLevelTestAndRules = ({
  appTestCasesGroup,
  onAddNewWindow,
  onAddTestCase,
  onUpdateTestCase,
  onDeleteTestCase,
  onAddRuleIn,
  onUpdateRuleIn,
  onUpdateRuleInCode,
  onDeleteRuleIn,
}: Props) => {
  const [isAddNewWindowOpen, setIsAddNewWindowOpen] = useState<boolean>(false)
  const [isAddingNewWindow, setIsAddingNewWindow] = useState<boolean>(false)
  const [isAddWindowChangeTriggerOpen, setIsAddWindowChangeTriggerOpen] = useState<boolean>(false)

  const {
    handleSubmit: handleAddNewWindowSubmit,
    formState: { errors: addNewWindowFormErrors },
    control: addNewWindowFormControl,
    reset: resetAddNewWindowForm,
  } = useForm<AddNewWindowFormData>({
    resolver: yupResolver(addNewWindowFormSchema) as Resolver<AddNewWindowFormData>,
  })

  const onAddNewWindowFormSubmit = async (formData: AddNewWindowFormData) => {
    setIsAddingNewWindow(true)
    onAddNewWindow(formData.windowName)
    setIsAddingNewWindow(false)
    resetAddNewWindowForm()
    setIsAddNewWindowOpen(false)
  }

  return (
    <div>
      <div className={isAddNewWindowOpen ? 'my-4 rounded-lg border border-gray-600 p-4' : 'my-4'}>
        <Button
          className='mb-3'
          iconStart={isAddNewWindowOpen ? <MinusIcon /> : <PlusIcon />}
          onClick={() => setIsAddNewWindowOpen((val) => !val)}
        >
          {isAddNewWindowOpen ? 'Hide Form' : 'Add New Window'}
        </Button>

        {isAddNewWindowOpen && (
          <form
            className='mt-2 flex items-end gap-2'
            autoComplete='off'
            onSubmit={handleAddNewWindowSubmit(onAddNewWindowFormSubmit)}
          >
            <Controller
              name='windowName'
              control={addNewWindowFormControl}
              render={({ field }) => (
                <InputField
                  label='Window Name'
                  error={addNewWindowFormErrors.windowName?.message}
                  {...field}
                  autoFocus
                />
              )}
            />
            <Button type='submit' disabled={isAddingNewWindow}>
              Add Window
            </Button>
          </form>
        )}
      </div>
      <div className='space-y-2'>
        <Text size='l' weight={500}>
          Window Rules
        </Text>
        <div className='space-y-8'>
          {appTestCasesGroup.windowLevelData.map(({ windowName, testCases, rulesForTestCases }) => (
            <WindowDisplay
              key={windowName}
              windowName={windowName}
              testCases={testCases}
              rulesForTestCases={rulesForTestCases}
              uiFieldNames={appTestCasesGroup.windowChangeTriggerRules.map(
                ({ dashboard_context }) => dashboard_context?.window_change_trigger_name ?? '',
              )}
              onAddTestCase={onAddTestCase}
              onUpdateTestCase={onUpdateTestCase}
              onDeleteTestCase={onDeleteTestCase}
              onAddRuleIn={onAddRuleIn}
              onUpdateRuleInCode={onUpdateRuleInCode}
              onUpdateRuleIn={onUpdateRuleIn}
              onDeleteRuleIn={onDeleteRuleIn}
            />
          ))}
        </div>
      </div>

      <div className='mt-8'>
        {(isAddWindowChangeTriggerOpen ||
          appTestCasesGroup.windowChangeTriggerRules.length > 0) && (
          <Text size='l' weight={500} className='mb-2'>
            UI Elements for Window Rules
          </Text>
        )}
        {appTestCasesGroup.rules.length > 0 && (
          <Link
            className='mb-3 inline-block'
            onClick={() => setIsAddWindowChangeTriggerOpen((val) => !val)}
          >
            {isAddWindowChangeTriggerOpen ? 'Hide' : 'Define UI Elements used in Window rules'}
          </Link>
        )}

        {isAddWindowChangeTriggerOpen && (
          <AddWindowChangeTriggerFields
            appRules={appTestCasesGroup.rules}
            onClose={() => setIsAddWindowChangeTriggerOpen(false)}
          />
        )}

        <div className='space-y-3'>
          {appTestCasesGroup.windowChangeTriggerRules.map((ruleIn) => (
            <WindowChangeTriggerFields ruleIn={ruleIn} key={ruleIn.id} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default WindowLevelTestAndRules
