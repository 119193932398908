import { useLazyGetStepSystemDistributionQuery } from '../../../../app/apiSliceProcessApi'
import { useQueryFilters } from '../../../../common/hooks'
import { downloadFile } from '../../../../common/utils/utils'
import type {
  ActivityCategorization,
  ServerTaskVisualizationNode,
} from '../../../TaskDiscovery/types'
import { type SystemDistributionData, WindowsRelationToStepEnum } from './NodeDataModal/types'
import type { StateVariantData, StepVariantData, TeamVariantData } from './types'

export const useDownloadVariantExport = () => {
  const [getStepSystemDistribution] = useLazyGetStepSystemDistributionQuery()
  const { processFilters, processId } = useQueryFilters()

  const downloadProcessVariantExportFile = async (
    processName: string,
    processDescription: string,
    selectedVariantData: TeamVariantData[] | StepVariantData[] | StateVariantData[],
  ) => {
    let stepsIndexOffset = 0

    const pseudoCodeGenerators = selectedVariantData.map((variant) => {
      const currentOffset = stepsIndexOffset
      stepsIndexOffset += variant.steps.length
      return onHandleOneProcessVariant(variant.steps, currentOffset)
    })

    const pseudoCodeForEachVariant = await Promise.all(pseudoCodeGenerators)

    compilePseudoCodeFile(processName, processDescription, pseudoCodeForEachVariant)
  }

  const downloadTaskVariantExportFile = async (
    processName: string,
    processDescription: string,
    selectedVariantData: StepVariantData[],
    nodeData: ServerTaskVisualizationNode[],
  ) => {
    const stepNameToActivitiesMapper = nodeData.reduce(
      (mapper, node) => {
        mapper[node.display_name] = node.activity_categorizations
        return mapper
      },
      {} as { [key: string]: ActivityCategorization[] },
    )

    let stepsIndexOffset = 0

    const pseudoCodeForEachVariant = selectedVariantData.map((variant) => {
      const currentOffset = stepsIndexOffset
      stepsIndexOffset += variant.steps.length
      return onHandleOneTaskVariant(variant.steps, currentOffset, stepNameToActivitiesMapper)
    })

    compilePseudoCodeFile(processName, processDescription, pseudoCodeForEachVariant)
  }

  const compilePseudoCodeFile = (
    processName: string,
    processDescription: string,
    pseudoCodeForEachVariant: Array<Array<(string | number | string[] | null[] | number[])[]>>,
  ) => {
    const rows = pseudoCodeForEachVariant
      .flat()
      .map((variant) => JSON.stringify(variant).replaceAll(',', ', '))

    // The pseudo-code json file needs to be formatted excactly as defined here else it
    // won't work. Please don't change formatting if you don't know what you are doing.
    const fullPseudoCode = `[\r\n  ["${processName}"],\r\n  ["${processDescription}"],\r\n  ${rows.join(',\r\n  ')}\r\n]`

    downloadFile(
      `data:text/json;charset=utf-8,${encodeURIComponent(fullPseudoCode)}`,
      'pi-process-export.json',
    )
  }

  const onHandleOneProcessVariant = async (
    variantStepNames: string[],
    stepsIndexOffset: number,
  ) => {
    const selectedStepData = await getVariantStepMultisystemWindows(variantStepNames)
    return getPseudoCodeForVariant(selectedStepData, stepsIndexOffset)
  }

  const getVariantStepMultisystemWindows = async (variantSteps: string[]) => {
    const multiSystemDataRequests = variantSteps.map((stepName) =>
      getStepSystemDistribution({
        processId,
        filters: { ...processFilters, zooming_filters: { step_name: stepName } },
        window_relation_to_step: WindowsRelationToStepEnum.MULTI_SYSTEM_WINDOW,
      }),
    )

    let multisSytemDataResults: Array<{ data: SystemDistributionData[] }>
    try {
      multisSytemDataResults = (await Promise.all(multiSystemDataRequests)) as Array<{
        data: SystemDistributionData[]
      }>
    } catch (error) {
      console.error(error)
      return []
    }

    return variantSteps.map((stepName, i) => ({
      stepName,
      description: getDescriptionForMultiSystemData(multisSytemDataResults[i].data),
    }))
  }

  const getDescriptionForMultiSystemData = (data: SystemDistributionData[]) => {
    const descriptions = [...data]
      .sort((a, b) => b.total_count - a.total_count)
      .slice(0, 5)
      .map(
        ({ window_name, total_count }: SystemDistributionData) =>
          `<p>${window_name} (${total_count} visits)</p>`,
      )
      .join('')
    return `<div>${descriptions}</div>`
  }

  const onHandleOneTaskVariant = (
    variantStepNames: string[],
    stepsIndexOffset: number,
    stepNameToActivitiesMapper: {
      [key: string]: ActivityCategorization[]
    },
  ) => {
    const selectedStepData = variantStepNames.map((stepName) => ({
      stepName,
      description: getDescriptionForTaskActivities(stepNameToActivitiesMapper[stepName]),
    }))

    return getPseudoCodeForVariant(selectedStepData, stepsIndexOffset)
  }

  const getDescriptionForTaskActivities = (data: ActivityCategorization[] | undefined) => {
    if (!data) {
      return ''
    }
    const descriptions = [...data]
      .sort((a, b) => b.activity_count - a.activity_count)
      .slice(0, 3)
      .map(
        ({ activity_name, activity_count }) => `<p>${activity_name} (${activity_count} visits)</p>`,
      )
      .join('')
    return `<div>${descriptions}</div>`
  }

  const getPseudoCodeForVariant = (
    selectedStepData: {
      stepName: string
      description: string
    }[],
    stepsIndexOffset: number,
  ) => {
    const stepPseudoCode = selectedStepData.map(({ stepName, description }, i) => {
      const [eventIndex, nextEvetnIndex] = getEventAndNextEventIndex(
        i,
        selectedStepData.length,
        stepsIndexOffset,
      )
      const eventType = getEventType(i, selectedStepData.length)
      const eventExecutor = ''
      const connectorNames = [] as string[]

      return [
        eventIndex,
        nextEvetnIndex,
        eventType,
        stepName,
        eventExecutor,
        connectorNames,
        description,
      ]
    })

    return stepPseudoCode
  }

  const getEventType = (i: number, stepsLength: number) => {
    if (i === 0) {
      return 'start event'
    }

    if (i === stepsLength - 1) {
      return 'end event'
    }
    return 'task'
  }

  const getEventAndNextEventIndex = (i: number, stepsLength: number, offset: number) => {
    // If last event, the next event index needs to be null.
    if (i === stepsLength - 1) {
      return [offset + i + 1, [null]]
    }

    return [offset + i + 1, [offset + i + 2]]
  }

  return { downloadProcessVariantExportFile, downloadTaskVariantExportFile }
}
