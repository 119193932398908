import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { Resolver } from 'react-hook-form/dist/types'
import * as yup from 'yup'

import { InlineLoader } from '../../../../common/components'
import { Button, InputField, Modal, Text } from '../../../../common/designs'
import type {
  ExportVariantsFormData,
  StateVariantData,
  StepVariantData,
  TeamVariantData,
} from './types'

interface Props {
  isOpen: boolean
  onClose: () => void
  selectedVariantData: TeamVariantData[] | StepVariantData[] | StateVariantData[]
  onExportVariants: (processName: string, processDescription: string) => Promise<void>
}

const MAX_VARIANT_AMOUNT_FOR_EXPORTING = 5

const formSchema = yup
  .object({
    processName: yup
      .string()
      .max(200, 'Process name cannot be more than 200 characters long')
      .required('Process Name is required'),
    processDescription: yup.string().max(9999),
  })
  .required()

const ExportVariantsModal = ({ isOpen, onClose, selectedVariantData, onExportVariants }: Props) => {
  const { handleSubmit, control, reset } = useForm<ExportVariantsFormData>({
    resolver: yupResolver(formSchema) as Resolver<ExportVariantsFormData>,
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    if (selectedVariantData.length > MAX_VARIANT_AMOUNT_FOR_EXPORTING) {
      setErrorMessage(
        `Max ${MAX_VARIANT_AMOUNT_FOR_EXPORTING} variants can be exported. Please select less variants before exporting.`,
      )
    } else {
      setErrorMessage(null)
    }
  }, [selectedVariantData])

  const onExport = async (formData: ExportVariantsFormData) => {
    setIsLoading(true)

    await onExportVariants(formData.processName, formData.processDescription)
    setIsLoading(false)
    reset()
    onClose()
  }

  if (errorMessage) {
    return (
      <Modal label='Error' open={isOpen} onClose={onClose}>
        <div className='mt-2'>
          <Text color='error'>{errorMessage}</Text>
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      label='Export Currently Selected Variants to Process Maker Platform?'
      open={isOpen}
      onClose={onClose}
      size='xl'
    >
      <div className='mt-2'>
        {isLoading ? (
          <div>
            <InlineLoader />{' '}
            <Text color='lightGray' size='s'>
              Generating export file. This might take some time.
            </Text>
          </div>
        ) : (
          <Text color='lightGray' size='s'>
            Export creates a file that you can download on your computer and upload to Process Maker
            Platform.
          </Text>
        )}
      </div>

      <form className='mt-5' autoComplete='off' onSubmit={handleSubmit(onExport)}>
        <Controller
          name='processName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <InputField
              size='s'
              className='mb-2'
              label='Process Name'
              autoComplete='off'
              error={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          name='processDescription'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <InputField
              size='s'
              label='Process Description'
              autoComplete='off'
              error={error?.message}
              {...field}
            />
          )}
        />

        <div className='mt-3 flex gap-3'>
          <Button
            className='inline-flex w-full justify-center sm:col-start-2'
            type='submit'
            loading={isLoading}
          >
            Export
          </Button>

          <Button
            variant='white'
            className='inline-flex w-full justify-center sm:col-start-2'
            onClick={onClose}
            loading={isLoading}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default ExportVariantsModal
