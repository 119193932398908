import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { Resolver, useForm } from 'react-hook-form'

import type { ConfigTestCase } from '../../../features/ConfigureDataCollectionAdminPanelPage/types'
import { DataCollectionTestCaseFormData } from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { Button, Text } from '../../designs'
import DataCollectionTestForm from '../DataCollectionForms/DataCollectionTestForm'
import { testCaseFormSchema } from '../DataCollectionForms/formSchemas'
import FormAndCodeDisplay from '../displays/FormAndCodeDisplay'
import { testCaseToFormData } from './dataHelpers'

interface Props {
  configTestCase: ConfigTestCase
  uiFieldNames: string[]
  hasError: boolean
  isNotTested: boolean
  errors: string[] | null | undefined
  onDeleteTestCase: (configTestCase: ConfigTestCase) => Promise<void>
  onUpdateTestCase: (
    data: DataCollectionTestCaseFormData,
    configTestCase: ConfigTestCase,
  ) => Promise<void>
}

const DataCollectionTestDisplay = ({
  configTestCase,
  uiFieldNames,
  hasError,
  isNotTested,
  errors,
  onDeleteTestCase,
  onUpdateTestCase,
}: Props) => {
  const { control, handleSubmit } = useForm<DataCollectionTestCaseFormData>({
    resolver: yupResolver(testCaseFormSchema) as Resolver<DataCollectionTestCaseFormData>,
    defaultValues: testCaseToFormData(configTestCase),
  })

  const onFormSubmit = async (data: DataCollectionTestCaseFormData) => {
    await onUpdateTestCase(data, configTestCase)
  }

  return (
    <div className='space-y-3'>
      {hasError && (
        <div>
          <div className='flex gap-2'>
            <ExclamationTriangleIcon className='size-5 text-red-700' />
            <Text color='error'>Test Fails</Text>
          </div>

          {(errors ?? []).map((err) => (
            <Text key={err} size='xs' color='error'>
              {err}
            </Text>
          ))}
        </div>
      )}
      {isNotTested && (
        <div className='flex gap-2'>
          <ExclamationTriangleIcon className='size-5 text-yellow-600' />
          <Text className='text-yellow-600'>Not Tested</Text>
        </div>
      )}
      <FormAndCodeDisplay
        formComponent={
          <form onSubmit={handleSubmit(onFormSubmit)} className='space-y-4'>
            <DataCollectionTestForm control={control} uiFieldNames={uiFieldNames} />
            <div className='space-x-2'>
              <Button size='s' type='submit'>
                Save
              </Button>
              <Button
                size='s'
                variant='destructive'
                onClick={() => onDeleteTestCase(configTestCase)}
              >
                Delete
              </Button>
            </div>
          </form>
        }
        codeComponent={
          <div className='mt-3 rounded-md'>
            <pre className='overflow-auto bg-gray-100 px-2 py-1'>
              <code>{JSON.stringify(configTestCase, undefined, 2)}</code>
            </pre>
          </div>
        }
      />
    </div>
  )
}

export default DataCollectionTestDisplay
