import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import ActiveFilterBar from '../ActiveFilterBar'
import ExportVariantsButton from './ExportVariantsButton'
import HighlightOptions from './HighlightOptions'
import VariantFlyoutStats from './VariantFlyoutStats'
import VariantSelector from './VariantSelector'
import VariantsTabSelector from './VariantsTabSelector'
import { useZoomingFilters } from './useZoomingFilters'

export const SecondLayerVariantsLayout = () => {
  const { activeZoomMode } = useZoomingFilters()
  const [isHighlightOptionsOpen, setIsHighlightOptionsOpen] = useState<boolean>(true)
  const [isVariantSelectorOpen, setIsVariantSelectorOpen] = useState<boolean>(false)

  return (
    <>
      <div className='flex h-[calc(100vh-186px)]'>
        {!activeZoomMode ? <VariantFlyoutStats /> : null}

        <Outlet />

        <div className='pointer-events-none absolute right-8 z-[1] mb-6 flex flex-col items-end space-y-2'>
          <VariantsTabSelector />

          <HighlightOptions
            setIsHighlightOptionsOpen={setIsHighlightOptionsOpen}
            isHighlightOptionsOpen={isHighlightOptionsOpen}
          />

          <div className='flex flex-wrap gap-2'>
            {!isVariantSelectorOpen && <ExportVariantsButton />}
            <VariantSelector
              isHighlightOptionsOpen={isHighlightOptionsOpen}
              isVariantSelectorOpen={isVariantSelectorOpen}
              setIsVariantSelectorOpen={setIsVariantSelectorOpen}
            />
          </div>
        </div>

        <div className='absolute bottom-0 left-24 mr-8'>
          <ActiveFilterBar />
        </div>
      </div>
    </>
  )
}

export default SecondLayerVariantsLayout
