import { Breadcrumbs, Divider } from '../../common/designs'
import { useEnhancedNavigate } from '../../common/hooks'
import AdvancedSetupSection from './AdvancedSetupSection'
import { AdvancedSetupSubPathEnum } from './types'

const AdvancedSetup = () => {
  const { enhancedNavigate: navigate } = useEnhancedNavigate()

  return (
    <>
      <Breadcrumbs
        variant='secondary'
        items={[
          {
            label: 'Configure Data Collection',
            onClick: () => navigate('..'),
          },
          { label: 'Advanced Setup' },
        ]}
      />

      <Divider className='my-4' color='lightGray' />

      <div className='grid grid-cols-2 gap-4'>
        <AdvancedSetupSection
          title='Configuration File Management'
          description='The Configuration File contains all data collection rules across different application. Download and upload the Configuration File manually. Upload custom files for specific teams.'
          navPath={AdvancedSetupSubPathEnum.CONFIG_FILE_MANAGEMENT}
        />
        <AdvancedSetupSection
          title='Test File Management'
          description='The Test File contains all tests across different application. Download and upload the Test File manually.'
          navPath={AdvancedSetupSubPathEnum.REQUIREMENT_FILE_MANAGEMENT}
        />
        <AdvancedSetupSection
          title='X-Ray File Management & Reactions'
          description='X-Ray Tooling allows you to capture elements from screen. Define UI element capturing with X-Ray Tools.'
          navPath={AdvancedSetupSubPathEnum.X_RAY_TOOLS}
        />

        <AdvancedSetupSection
          title='X-Ray Pattern Tester'
          description='X-Ray Pattern Tester helps you to verify that a pattern captures the correct values from X-Ray files.'
          navPath={AdvancedSetupSubPathEnum.X_RAY_PATTERN_TESTER}
        />
      </div>
    </>
  )
}

export default AdvancedSetup
