import { ArrowUpOnSquareStackIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import { Button } from '../../../../common/designs'
import ExportProcessVariantsModal from './ExportProcessVariantsModal'

const ExportVariantsButton = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        variant='white'
        size='xs'
        iconStart={<ArrowUpOnSquareStackIcon />}
        onClick={() => setIsModalOpen(true)}
        className='pointer-events-auto'
      >
        Export Variants
      </Button>

      <ExportProcessVariantsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}

export default ExportVariantsButton
