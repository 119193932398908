import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Resolver, useForm } from 'react-hook-form'

import ItemAccordion from '../../../features/ConfigureDataCollectionAdminPanelPage/ItemAccordion'
import type {
  DataCollectionFromUIElementRuleFormData,
  DataCollectionRuleFormData,
  RuleIn,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { Text } from '../../designs'
import DataCollectionRuleForm from '../DataCollectionForms/DataCollectionRuleForm'
import { manualRuleForm } from '../DataCollectionForms/formSchemas'
import AddNewFormDisplay from './AddNewFormDisplay'
import DataCollectionRuleDisplay from './DataCollectionRuleDisplay'

interface Props {
  rulesForTestCases: RuleIn[]
  uiFieldNames?: string[]
  onAddRuleIn: (
    formValues: DataCollectionRuleFormData,
    uiFieldValues: DataCollectionFromUIElementRuleFormData,
  ) => Promise<void>
  onUpdateRuleIn: (
    formData: DataCollectionRuleFormData,
    originalRule: RuleIn,
    uiFieldValues: DataCollectionFromUIElementRuleFormData,
  ) => Promise<void>
  onUpdateRuleInCode: (newRule: RuleIn) => Promise<void>
  onDeleteRuleIn: (rule: RuleIn) => Promise<void>
  isForProcessConfigs?: boolean
  formInitialValues?: DataCollectionRuleFormData
}

const DataCollectionRules = ({
  rulesForTestCases,
  uiFieldNames = [],
  onAddRuleIn,
  onUpdateRuleIn,
  onUpdateRuleInCode,
  onDeleteRuleIn,
  isForProcessConfigs = false,
  formInitialValues = {
    inTitle: [{ value: '' }],
    inUrl: [{ value: '' }],
  },
}: Props) => {
  const { control, handleSubmit } = useForm<DataCollectionRuleFormData>({
    resolver: yupResolver(manualRuleForm) as Resolver<DataCollectionRuleFormData>,
    defaultValues: formInitialValues,
  })

  const [uiFieldNameInputs, setUiFieldNameInputs] =
    useState<DataCollectionFromUIElementRuleFormData>(
      uiFieldNames.reduce((mapper, fieldName) => {
        mapper[fieldName] = ['']
        return mapper
      }, {} as DataCollectionFromUIElementRuleFormData),
    )

  // Generate DataCollectionRuleDisplay components for accordion.
  const accordionData = rulesForTestCases.map((ruleData, i) => ({
    label: ruleData.description ?? `Rule ${i + 1}`,
    key: ruleData.description ?? `Rule ${i + 1}`,
    component: (
      <DataCollectionRuleDisplay
        ruleJson={ruleData}
        isEditableFromForm={Boolean(ruleData.is_dashboard_generated)}
        uiFieldNames={uiFieldNames}
        onUpdateRuleIn={onUpdateRuleIn}
        onUpdateRuleInCode={onUpdateRuleInCode}
        onDeleteRuleIn={onDeleteRuleIn}
        isForProcessConfigs={isForProcessConfigs}
      />
    ),
  }))

  const onSaveRule = (formValues: DataCollectionRuleFormData) => {
    onAddRuleIn(formValues, uiFieldNameInputs)
  }

  return (
    <div className='space-y-4'>
      <div className='overflow-hidden bg-white px-4 py-2 shadow sm:rounded-lg'>
        <Text className='mb-2' weight={500} color='darkGray'>
          Data Collection Rules
        </Text>
        <div>
          <ItemAccordion data={accordionData} />
        </div>
      </div>

      <AddNewFormDisplay onSubmit={handleSubmit(onSaveRule)} label='Add New Rule'>
        <DataCollectionRuleForm
          control={control}
          isForProcessConfigs={isForProcessConfigs}
          uiFieldNameInputs={uiFieldNameInputs}
          setUiFieldNameInputs={setUiFieldNameInputs}
        />
      </AddNewFormDisplay>
    </div>
  )
}

export default DataCollectionRules
