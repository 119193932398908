import { useGetProcessStepUserActionAnalysisQuery } from '../../../../../app/apiSliceProcessApi'
import { useQueryFilters } from '../../../../../common/hooks'
import { UserActionTypeEnum } from '../../../types'
import { UserActivitiesFieldChangeView } from '../../UserActivitiesFieldChange'

const StepUserActivitiesFieldChange = () => {
  const { processFiltersWithZooming: filters, processId } = useQueryFilters()
  const { data, isFetching } = useGetProcessStepUserActionAnalysisQuery({
    filters,
    processId,
    action_type: UserActionTypeEnum.FIELD_EDIT,
  })

  return <UserActivitiesFieldChangeView data={data} isFetching={isFetching} />
}

export default StepUserActivitiesFieldChange
