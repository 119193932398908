import { PencilIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Controller, Resolver, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useValidateDataCollectionRulesMutation } from '../../../app/apiSlice'
import { generateFormDataFromRule } from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/configInputTransformations'
import type {
  DataCollectionFromUIElementRuleFormData,
  DataCollectionRuleFormData,
  RuleIn,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { ActionModal, Button, IconButton, Modal, Text, TextArea } from '../../designs'
import type { HTTPException } from '../../types/api'
import DataCollectionRuleForm from '../DataCollectionForms/DataCollectionRuleForm'
import { manualRuleForm } from '../DataCollectionForms/formSchemas'
import { InlineLoader } from '../Loader'
import FormAndCodeDisplay from '../displays/FormAndCodeDisplay'
import type { RuleCodeForm } from './types'

interface Props {
  ruleJson: RuleIn
  isEditableFromForm?: boolean
  uiFieldNames?: string[]
  onUpdateRuleIn?: (
    formData: DataCollectionRuleFormData,
    originalRule: RuleIn,
    uiFieldValues: DataCollectionFromUIElementRuleFormData,
  ) => Promise<void>
  onUpdateRuleInCode?: (newRule: RuleIn) => Promise<void>
  onDeleteRuleIn: (rule: RuleIn) => Promise<void>
  isForProcessConfigs?: boolean | undefined
}

const DataCollectionRuleDisplay = ({
  ruleJson,
  isEditableFromForm,
  uiFieldNames = [],
  onUpdateRuleIn,
  onUpdateRuleInCode,
  onDeleteRuleIn,
  isForProcessConfigs,
}: Props) => {
  const { standardFormValues, uiFieldFormValues } = generateFormDataFromRule(ruleJson, uiFieldNames)
  const {
    control: ruleFormControl,
    handleSubmit: handleRuleFormSubmit,
    reset: resetRuleForm,
  } = useForm<DataCollectionRuleFormData>({
    resolver: yupResolver(manualRuleForm) as Resolver<DataCollectionRuleFormData>,
    defaultValues: standardFormValues,
  })
  const {
    control: ruleCodeControl,
    handleSubmit: handleRuleCodeSubmit,
    getValues: getRuleCodeValues,
    setValue: setRuleCodeValue,
  } = useForm<RuleCodeForm>({
    resolver: yupResolver(yup.object({ code: yup.string() })) as Resolver<RuleCodeForm>,
    defaultValues: {
      code: JSON.stringify(ruleJson, undefined, 2),
    },
  })

  const [validateRules, { isLoading: isLoadingValidation }] =
    useValidateDataCollectionRulesMutation()

  const [isEditConfirmationModalOpen, setIsEditConfirmationModalOpen] = useState<boolean>(false)
  const [isEditCodeModeActive, setIsEditCodeModeActive] = useState<boolean>(false)
  const [codeError, setCodeError] = useState<string | null>(null)
  const [uiFieldNameInputs, setUiFieldNameInputs] =
    useState<DataCollectionFromUIElementRuleFormData>(uiFieldFormValues)

  // Reset the form values when the ruleJson changes.
  useEffect(() => {
    const { standardFormValues, uiFieldFormValues } = generateFormDataFromRule(
      ruleJson,
      uiFieldNames,
    )
    resetRuleForm(standardFormValues)
    setUiFieldNameInputs(uiFieldFormValues)
  }, [resetRuleForm, ruleJson, uiFieldNames])

  const onSaveRuleForm = async (formData: DataCollectionRuleFormData) => {
    onUpdateRuleIn && (await onUpdateRuleIn(formData, ruleJson, uiFieldNameInputs))
  }

  const onDelete = async () => {
    await onDeleteRuleIn(ruleJson)
  }

  const validateCode = async (code: string) => {
    let ruleIn

    // Validate that code is valid JSON
    try {
      ruleIn = JSON.parse(code) as RuleIn
    } catch (err) {
      if (typeof err === 'string') setCodeError(err)
      else if (err instanceof Error) setCodeError(err.message)
      return
    }

    // Validate from server that the code is valid Rule object
    const res = await validateRules({ rules: [ruleIn] })
    if (res && 'error' in res) {
      const errorData = (res as HTTPException).error.data
      return setCodeError(errorData.detail ?? errorData.message ?? null)
    }

    return ruleIn
  }

  const onSaveRuleCode = async ({ code }: RuleCodeForm) => {
    const ruleIn = await validateCode(code)
    if (!ruleIn) {
      return
    }

    setCodeError(null)
    // Set rule as non-dashboard-generated so that it can't
    // be modified through the no-code features.
    ruleIn.is_dashboard_generated = false
    onUpdateRuleInCode && onUpdateRuleInCode(ruleIn)
  }

  const onFormatCode = async () => {
    const code = getRuleCodeValues('code')
    let ruleIn
    // Validate that code is valid JSON
    try {
      ruleIn = JSON.parse(code) as RuleIn
    } catch (err) {
      if (typeof err === 'string') setCodeError(err)
      else if (err instanceof Error) setCodeError(err.message)
      return
    }
    setCodeError(null)
    setRuleCodeValue('code', JSON.stringify(ruleIn, undefined, 2))
  }

  return (
    <>
      <div className='space-y-3'>
        <FormAndCodeDisplay
          formComponent={
            isEditableFromForm ? (
              <form onSubmit={handleRuleFormSubmit(onSaveRuleForm)} className='space-y-4'>
                <DataCollectionRuleForm
                  control={ruleFormControl}
                  isForProcessConfigs={isForProcessConfigs}
                  uiFieldNameInputs={uiFieldNameInputs}
                  setUiFieldNameInputs={setUiFieldNameInputs}
                />
                <div className='space-x-2'>
                  <Button size='s' type='submit'>
                    Save
                  </Button>
                  <Button size='s' variant='destructive' onClick={onDelete}>
                    Delete
                  </Button>
                </div>
              </form>
            ) : null
          }
          codeComponent={
            <div className='mt-3 rounded-md'>
              <pre className='relative overflow-auto bg-gray-100 px-2 py-1'>
                <>
                  <code>{JSON.stringify(ruleJson, undefined, 2)}</code>
                  <IconButton
                    icon={<PencilIcon />}
                    variant='white'
                    className='absolute bottom-2 right-2'
                    onClick={() =>
                      isEditableFromForm
                        ? setIsEditConfirmationModalOpen(true)
                        : setIsEditCodeModeActive(true)
                    }
                  />
                </>
              </pre>
              <Button variant='destructive' onClick={onDelete}>
                Delete
              </Button>
            </div>
          }
        />
      </div>

      <ActionModal
        variant='primary'
        label='Are you sure you want to edit rule code manually?'
        description="If you edit the code manually, you won't be able to change this rule using no-code tools later on."
        actionLabel='Edit'
        open={isEditConfirmationModalOpen}
        onClose={() => setIsEditConfirmationModalOpen(false)}
        onAction={() => {
          setIsEditConfirmationModalOpen(false)
          setIsEditCodeModeActive(true)
        }}
      />

      <Modal
        size='4xl'
        label='Edit Rule'
        open={isEditCodeModeActive}
        onClose={() => setIsEditCodeModeActive(false)}
      >
        {isLoadingValidation ? (
          <InlineLoader />
        ) : (
          <form onSubmit={handleRuleCodeSubmit(onSaveRuleCode)}>
            <Controller
              name='code'
              control={ruleCodeControl}
              render={({ field, fieldState: { error } }) => (
                <TextArea
                  rows={16}
                  placeholder='Add a description'
                  error={error?.message}
                  {...field}
                />
              )}
            />
            {codeError && (
              <Text className='my-1' color='error' size='s'>
                {codeError}
              </Text>
            )}
            <div className='mt-2 flex gap-2'>
              <Button type='submit' size='s'>
                Save
              </Button>
              <Button variant='white' size='s' onClick={onFormatCode}>
                Format
              </Button>
            </div>
          </form>
        )}
      </Modal>
    </>
  )
}

export default DataCollectionRuleDisplay
