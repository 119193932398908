import { Transition } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/20/solid'
import {
  ArrowPathIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useCreateConformanceFlowMutation } from '../../../../app/apiSlice'
import { Button, Divider, Select, Text } from '../../../../common/designs'
import { useAppDispatch, useAppSelector } from '../../../../common/hooks'
import { generateIncrementalIndexesArrayUntil } from '../../../../common/utils/utils'
import VariantSelectBox from '../../VariantSelectionDrawers/VariantSelectBox'
import VariantSelectionContent from '../../VariantSelectionDrawers/VariantSelectionContent'
import {
  getStateChangeFlowMetricLabel,
  getStateChangeFlowMetricValue,
  getTransactionFlowMetricLabel,
  getTransactionFlowMetricValue,
  stateChangeFlowOrderByOptions,
  windowFlowOrderByOptions,
} from '../../VariantSelectionDrawers/sortValueMappers'
import { OrderByDirectionEnum } from '../../constants'
import { selectVariantOrder, setVariantOrder } from '../../processesSlice'
import type { ConformanceModalData, OrderByVariableValue } from '../../types'
import { ConformanceTypeEnum } from '../../types'
import { getProcessIdFromLocation, getVariantViewRoutesEnumFromLocation } from '../../utils'
import { useSelectedVariantsIndicies } from './/useSelectedVariantsIndicies'
import type { StateVariantData, StepVariantData, TeamVariantData } from './types'
import { VariantVisualizationLevelEnum } from './types'
import { useGetVariantData } from './useGetVariantData'

interface Props {
  isHighlightOptionsOpen: boolean
  isVariantSelectorOpen: boolean
  setIsVariantSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const VariantSelector = ({
  isHighlightOptionsOpen,
  isVariantSelectorOpen,
  setIsVariantSelectorOpen,
}: Props) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const processId = getProcessIdFromLocation(location)
  const activeRoute = getVariantViewRoutesEnumFromLocation(location)
  const variantOrder = useAppSelector(selectVariantOrder)
  const variantData = useGetVariantData(activeRoute)
  const {
    stepLevelVariantIndices,
    stateLevelVariantIndices,
    teamLevelVariantIndices,
    windowLevelVariantIndices,
    updateSelectedVariantIndicies,
  } = useSelectedVariantsIndicies()
  const [saveConformanceFlow] = useCreateConformanceFlowMutation()

  const [shownVariants, setShownVariants] = useState<number>(0)
  const [orderByDirection, setOrderByDirection] = useState<OrderByDirectionEnum>(
    variantOrder.direction,
  )
  const [orderByVariable, setOrderByVariable] = useState<OrderByVariableValue>(
    variantOrder.variable,
  )

  const selectedVariants = useMemo(() => {
    switch (variantData.visualizationLevel) {
      case VariantVisualizationLevelEnum.STEP:
        return stepLevelVariantIndices

      case VariantVisualizationLevelEnum.STATE:
        return stateLevelVariantIndices

      case VariantVisualizationLevelEnum.TEAM:
        return teamLevelVariantIndices

      case VariantVisualizationLevelEnum.WINDOW:
        return windowLevelVariantIndices

      default:
        return [0]
    }
  }, [
    variantData.visualizationLevel,
    stepLevelVariantIndices,
    stateLevelVariantIndices,
    teamLevelVariantIndices,
    windowLevelVariantIndices,
  ])

  useEffect(() => {
    shownVariants < 50 && setShownVariants(50)
  }, [shownVariants])

  const [selectedVariantsForUpdate, setSelectedVariantsForUpdate] = useState<number[]>([
    ...selectedVariants,
  ])

  const directionData = Object.values(OrderByDirectionEnum).map((o) => {
    return { label: o, value: o }
  })

  const maxAmountOfVariants = variantData.allVariantDataArray.length

  let orderByOptions,
    getMetricValue: (
      orderVariable: OrderByVariableValue,
      variant: TeamVariantData | StepVariantData | StateVariantData,
    ) => string,
    variantMetricLabel: string

  switch (variantData.visualizationLevel) {
    case VariantVisualizationLevelEnum.STEP:
    case VariantVisualizationLevelEnum.WINDOW: // TODO
      orderByOptions = windowFlowOrderByOptions
      getMetricValue = getTransactionFlowMetricValue
      variantMetricLabel = getTransactionFlowMetricLabel(variantOrder.variable)
      break

    case VariantVisualizationLevelEnum.STATE:
    case VariantVisualizationLevelEnum.TEAM:
      orderByOptions = stateChangeFlowOrderByOptions
      getMetricValue = getStateChangeFlowMetricValue
      variantMetricLabel = getStateChangeFlowMetricLabel(variantOrder.variable)
      break

    default:
      orderByOptions = windowFlowOrderByOptions
      getMetricValue = getTransactionFlowMetricValue
      variantMetricLabel = getTransactionFlowMetricLabel(variantOrder.variable)
      break
  }

  const onSaveConformanceFlow = async (data: ConformanceModalData) => {
    await saveConformanceFlow({
      processId,
      body: {
        ...data,
        conformance_type:
          variantData.visualizationLevel === VariantVisualizationLevelEnum.STEP
            ? ConformanceTypeEnum.STEP_FLOW
            : ConformanceTypeEnum.STATE_FLOW,
      },
    })
  }

  const renderSelectButton = (label: string, amount: number) => {
    const totalSelections = amount <= maxAmountOfVariants ? amount : maxAmountOfVariants

    return (
      <Button
        variant='white'
        size='xs'
        onClick={() => {
          setSelectedVariantsForUpdate(generateIncrementalIndexesArrayUntil(totalSelections))
        }}
      >
        {label}
      </Button>
    )
  }

  const onUpdateView = () => {
    updateSelectedVariantIndicies(selectedVariantsForUpdate, variantData.visualizationLevel)
    dispatch(setVariantOrder({ variable: orderByVariable, direction: orderByDirection }))
  }

  return (
    <>
      {isVariantSelectorOpen ? null : (
        <Button
          variant='white'
          size='xs'
          iconStart={<ChevronDoubleLeftIcon />}
          onClick={() => setIsVariantSelectorOpen((s) => !s)}
          className='pointer-events-auto'
        >
          Variants ({selectedVariants.length})
        </Button>
      )}

      <Transition
        as={Fragment}
        show={isVariantSelectorOpen}
        enter='transform transition duration-300 ease-in-out'
        enterFrom='opacity-0 scale-0'
        enterTo='opacity-100 scale-100'
        leave='transform transition duration-300 ease-in-out'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-0'
      >
        <div className='pointer-events-auto size-full origin-top-right rounded-md bg-white shadow-md ring-1 ring-gray-200'>
          {isVariantSelectorOpen ? (
            <>
              <div className='p-2'>
                <div className='flex justify-between transition-all duration-[3000] ease-in-out'>
                  <Button
                    variant='secondary'
                    size='xs'
                    iconStart={<ArrowPathIcon />}
                    onClick={onUpdateView}
                    disabled={selectedVariantsForUpdate.length === 0}
                  >
                    Update variants to the view
                  </Button>

                  <Button
                    variant='white'
                    size='xs'
                    iconEnd={<ChevronDoubleRightIcon />}
                    onClick={() => setIsVariantSelectorOpen((s) => !s)}
                  >
                    Variants ({selectedVariants.length})
                  </Button>
                </div>
              </div>

              <Divider className='my-0.5' />

              <div className='flex justify-between gap-2 p-2'>
                <Select
                  label='Order by'
                  size='xs'
                  options={orderByOptions}
                  value={orderByVariable}
                  onChange={setOrderByVariable as Dispatch<SetStateAction<string>>}
                  className='w-full'
                />

                <Select
                  label='Order direction'
                  size='xs'
                  options={directionData}
                  value={orderByDirection}
                  onChange={setOrderByDirection as Dispatch<SetStateAction<string>>}
                  className='w-full'
                />
              </div>

              <Divider className='my-0.5' />

              <div className='p-2'>
                <Text size='s' color='gray' weight={500}>
                  Variant amount quick selection
                </Text>

                <div className='mt-1 space-x-2'>
                  {renderSelectButton('None', 0)}
                  {renderSelectButton('Top 5', 5)}
                  {renderSelectButton('Top 10', 10)}
                  {renderSelectButton('Top 15', 15)}
                  {renderSelectButton('Top 20', 20)}
                  {renderSelectButton(`All (${maxAmountOfVariants})`, maxAmountOfVariants)}
                </div>
              </div>

              <Divider className='my-0.5' />

              <div
                className={clsx(
                  'w-[432px] overflow-y-auto overflow-x-hidden p-2',
                  isHighlightOptionsOpen ? 'h-[calc(100vh-640px)]' : 'h-[calc(100vh-500px)]',
                )}
              >
                {variantData.allVariantDataArray?.slice(0, shownVariants).map((variant, i) => (
                  <VariantSelectBox
                    isActive={selectedVariantsForUpdate.includes(i)}
                    key={i}
                    variantSteps={variant.steps}
                    variantIndex={i}
                    selectedVariantsForUpdate={selectedVariantsForUpdate}
                    setSelectedVariantsForUpdate={setSelectedVariantsForUpdate}
                  >
                    <VariantSelectionContent
                      index={i}
                      variantTransactionAmount={variant.transactions}
                      totalTransactionAmount={variantData.totalTransactionAmount}
                      additionalMetricLabel={variantMetricLabel}
                      additionalMetricValue={getMetricValue(variantOrder.variable, variant)}
                      variantSteps={variant.steps}
                      isConformanceFlow={variant.is_conformance_flow}
                      visualizationLevel={variantData.visualizationLevel}
                      onSaveConformanceFlow={onSaveConformanceFlow}
                    />
                  </VariantSelectBox>
                ))}

                {shownVariants <= maxAmountOfVariants && (
                  <div className='mt-3 flex gap-4'>
                    <Button
                      variant='white'
                      onClick={() => setShownVariants(shownVariants - 50)}
                      iconEnd={<MinusSmallIcon />}
                      disabled={shownVariants === 50}
                      wFull
                    >
                      Show less
                    </Button>

                    <Button
                      variant='white'
                      onClick={() => setShownVariants(shownVariants + 50)}
                      iconEnd={<PlusSmallIcon />}
                      disabled={shownVariants >= maxAmountOfVariants}
                      wFull
                    >
                      Show more
                    </Button>
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
      </Transition>
    </>
  )
}

export default VariantSelector
