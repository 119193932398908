import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { sub } from 'date-fns'
import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import { useGetPluginPingsStatusQuery } from '../../app/apiSlice'
import { Loader } from '../../common/components'
import { Text } from '../../common/designs'
import { ChartData } from '../../common/types/common'
import { GranularityEnum } from '../../common/types/monitoring'
import type { Team } from '../../common/utils/UtilityWrapper/types'
import { getColorBasedOnValuePercentage } from '../../common/utils/colorUtils'
import { getDatesInRange } from '../../common/utils/dateTimeUtils'

interface Props {
  title: string
  team?: Team | undefined
  daysSelected: number
  expectedAgentAmount: number | null
}

const ConnectivityGraph = ({ title, team, daysSelected, expectedAgentAmount }: Props) => {
  const [chartData, setChartData] = useState<null | ChartData>(null)
  const [timePeriod, setTimePeriod] = useState<{
    start_timestamp: string
    end_timestamp: string
  } | null>(null)

  const { data, isFetching } = useGetPluginPingsStatusQuery(
    {
      ...(team?.id && { team_ids: [team.id] }),
      start_timestamp: timePeriod?.start_timestamp ?? '',
      end_timestamp: timePeriod?.end_timestamp ?? '',
      granularity: GranularityEnum.DAY,
    },
    { skip: !timePeriod },
  )

  useEffect(() => {
    const today = new Date() // Defaults time to host computer!

    setTimePeriod({
      start_timestamp: sub(today, { days: daysSelected - 1 }).toISOString(),
      end_timestamp: today.toISOString(),
    })
  }, [daysSelected])

  useEffect(() => {
    if (!timePeriod || !data) return

    // Generate the array of dates
    const datesArray = getDatesInRange(
      new Date(timePeriod.start_timestamp),
      new Date(timePeriod.end_timestamp),
    )

    // Map the dates to the API data
    const rawChartData = datesArray.map((date) => {
      const dateStr = date.toISOString().split('T')[0] // Format date as 'YYYY-MM-DD'
      const apiDataForDate = data.find((d) => d.grouping_timestamp.startsWith(dateStr))

      return {
        label: dateStr,
        count: apiDataForDate ? apiDataForDate.computer_count : 0,
        backgroundColor:
          expectedAgentAmount == null
            ? 'hsla(245,58%,51%,0.8)'
            : getColorBasedOnValuePercentage(
                apiDataForDate ? apiDataForDate.computer_count : 0,
                expectedAgentAmount,
              ),
      }
    })

    const chartData = {
      labels: rawChartData.map((item) => item.label),
      datasets: [
        {
          label: 'Agents connected',
          data: rawChartData.map((item) => item.count),
          backgroundColor: rawChartData.map((item) => item.backgroundColor),
        },
      ],
    }

    setChartData(chartData)
  }, [data, timePeriod, expectedAgentAmount])

  if (!chartData || isFetching) return <Loader />

  const annotationColor = 'hsla(239, 84%, 67%, 1)'
  const computerDataPoints = chartData.datasets[0].data
  const { minComputers, avgComputers, maxComputers } = {
    minComputers: Math.min(...computerDataPoints),
    avgComputers: computerDataPoints.reduce((a, b) => a + b, 0) / computerDataPoints.length,
    maxComputers: Math.max(...computerDataPoints),
  }

  return (
    <div className='rounded-md bg-white p-4 shadow-md'>
      <div className='flex justify-between'>
        <div className='mb-2 mr-2 flex gap-4'>
          <Text>{title}</Text>

          {avgComputers === 0 ? (
            <div className='flex items-center gap-2 rounded-md border border-red-300 bg-red-50 px-1.5 py-1'>
              <ExclamationTriangleIcon className='size-5 text-red-600' />

              <Text size='xs' color='error' weight={500}>
                No computers have been detected!
              </Text>
            </div>
          ) : null}
        </div>

        {computerDataPoints.length ? (
          <>
            <div className='flex gap-8'>
              <Text className='mb-2' size='s'>
                Minimum: {minComputers}
              </Text>
              <Text className='mb-2' size='s'>
                Average: {Math.round(avgComputers * 10) / 10}
              </Text>
              <Text className='mb-2' size='s'>
                Maximum: {maxComputers}
              </Text>
            </div>
          </>
        ) : (
          <Text className='mb-2' size='s'>
            This team does not have any computers connected yet
          </Text>
        )}
      </div>

      {computerDataPoints.length ? (
        <Bar
          data={chartData}
          options={{
            scales: {
              y: {
                ticks: {
                  font: {
                    family: 'Inter',
                  },
                  stepSize: 1,
                },
                suggestedMax:
                  // Scale the graph by the biggest value found (either max computer or expected agent amount)
                  maxComputers > (expectedAgentAmount ?? 0)
                    ? maxComputers + 1
                    : (expectedAgentAmount ?? 0) + 1,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              annotation: {
                annotations: expectedAgentAmount
                  ? {
                      line1: {
                        type: 'line',
                        yMin: expectedAgentAmount,
                        yMax: expectedAgentAmount,
                        borderColor: annotationColor,
                        borderWidth: 1,
                        label: {
                          content: 'Expected computer amount',
                          backgroundColor: annotationColor,
                          display: true,
                        },
                      },
                    }
                  : {},
              },
            },
          }}
          height='60px'
        />
      ) : null}
    </div>
  )
}

export default ConnectivityGraph

