import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  apiSlice,
  useConvertSocialTokenToAccessTokenMutation,
  useLazyGetFileStoreTokenQuery,
  useLazyValidateUserInvitationQuery,
} from '../../app/apiSlice'
import { WEB_APP_URL_DASHBOARD_FRONTEND } from '../../app/configs'
import { useAppDispatch, useLocalStorage } from '../../common/hooks'
import { GlobalPathEnum, MainPathEnum } from '../../common/types/routing'
import { StorageNameEnum } from '../../common/types/storage'
import { isString } from '../../common/types/typeGuards'
import { loginRequest } from './aadConfig'
import { AUTH_BACKEND_TYPE, AUTH_GRANT_TYPE } from './enums'

export const getAccessToken = () => {
  const storageItem = localStorage.getItem(StorageNameEnum.TOKEN)
  if (!storageItem) return null

  const accessToken = JSON.parse(storageItem).content.access_token
  if (!isString(accessToken)) return null

  return accessToken
}

export const onHooklessSignOut = () => {
  // Check if the iFrame login method is enabled.
  const storageItem = localStorage.getItem(StorageNameEnum.IFRAME)
  if (storageItem) {
    const { content: iFrame } = JSON.parse(storageItem)
    if (iFrame?.isEnabled) {
      window.location.href = `${WEB_APP_URL_DASHBOARD_FRONTEND}/${GlobalPathEnum.AUTOMATIC_SIGN_IN}?token=${iFrame?.token}`
      localStorage.clear()
      return
    }
  }

  // If no iFrame login is enabled, redirect to manual sign-in page.
  if (window.location.pathname !== `/${GlobalPathEnum.SIGN_IN}`) {
    window.location.href = `${WEB_APP_URL_DASHBOARD_FRONTEND}/${GlobalPathEnum.SIGN_IN}`
  }
  localStorage.clear()
}

const useAuthentication = () => {
  const dispatch = useAppDispatch()
  const { instance } = useMsal()
  const storage = useLocalStorage()
  const navigate = useNavigate()

  const [validateUserInvitation] = useLazyValidateUserInvitationQuery()
  const [convertSocialTokenToAccessToken, { isLoading }] =
    useConvertSocialTokenToAccessTokenMutation()
  const [getFileStoreToken] = useLazyGetFileStoreTokenQuery()

  const onSignIn = () => {
    const state = crypto.randomUUID()
    // 1. Authenticate user through AAD.
    instance.loginPopup({ ...loginRequest, state }).then((res) => {
      // 2. Validate that state has not been tampered with during request-response lifecycle.
      // https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-pass-custom-state-authentication-request
      if (state !== res.state) toast.error('Request and response state does not match')
      else {
        // 3. Validate that the user has a valid invitation if its a new user.
        validateUserInvitation({ email: res?.account?.username ?? '' })
          .unwrap()
          .then(() => {
            // 4. Convert the AAD token to a Django token
            convertSocialTokenToAccessToken({
              token: res.accessToken,
              backend: AUTH_BACKEND_TYPE.AAD,
              grant_type: AUTH_GRANT_TYPE.CONVERT_TOKEN,
            })
              .unwrap()
              .then((token) => {
                // 5. Sign in the user and update the local storage with token information.
                storage.setItem(StorageNameEnum.TOKEN, token)
                navigate(`/${MainPathEnum.DEFAULT}`)
              })
          })
      }
    })
  }

  const onSignOut = () => {
    navigate(`/${GlobalPathEnum.SIGN_IN}`)
    storage.clearItems()
    dispatch(apiSlice.util.resetApiState())
  }

  const onFileStoreTokenExpiry = () => {
    getFileStoreToken()
      .unwrap()
      .then((fileStore) => {
        storage.updateNestedItem(StorageNameEnum.DASHBOARD, 'fileStore', fileStore)
      })
  }

  return { onSignIn, isLoading, onSignOut, onFileStoreTokenExpiry }
}

export default useAuthentication

