import type { GroupByField, ProcessIdQueryParamsBase, TransactionFilters } from '../../types'
import { TagFilteringLogicEnum } from '../Analysis/types'

export interface DefaultDimensionFilterData {
  displayName: string
  requestFieldName: string
  requestFieldNameMatchAll?: string
  isEditable: boolean
}

export type DimensionFilterOption = string | string[] | number

export interface FilterConfigData {
  key: string
  requestFieldName: string
  requestFieldNameMatchAll?: string
  isEditable: boolean
  displayName?: string
  options?: DimensionFilterOption[]
  shouldUseMatchAll: boolean
  selectedOptions?: DimensionFilterOption[]
  tagName?: string
  tagFilteringLogic?: TagFilteringLogicEnum
}

export interface FilterDimemsionOptionData {
  group_by: GroupByField
  options: Array<string | number>
  display_name?: string
}

export interface FilterUserActionOptionData {
  click_actions: { [key: string]: string[] }
  data_input_contents: string[]
}

export interface FilterDimemsionRequestData {
  group_by: GroupByField
  display_name?: string
}

export interface FilterDimemsionOptionParams extends ProcessIdQueryParamsBase {
  group_by_fields: FilterDimemsionRequestData[]
  filters: TransactionFilters
}

export enum InteractionFilterOptionEnum {
  COPY_COUNT = 'copy_count_filter',
  PASTE_COUNT = 'paste_count_filter',
  KEYBOARD_COUNT = 'keyboard_press_count_filter',
  MOUSE_CLICK_COUNT = 'mouse_click_count_filter',
}

export enum UserActionFilterOptionEnum {
  CLICK_ACTION = 'click_action_filter',
  FIELD_EDIT = 'field_edit_filter',
  DATA_INPUT_CONTENTS = 'data_input_content_filter',
}
