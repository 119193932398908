import { useMemo } from 'react'

import { useGetProcessMonitorStatusQuery } from '../../../app/apiSliceProcessApi'
import { CHART_NEUTRAL_HSLA, CHART_PRIMARY_HSLA } from '../../../app/theme'
import { AdminMainPathEnum } from '../../../common/types/routing'
import StatusCard from './StatusCard'

const ProcessStatus = () => {
  const { data, isLoading } = useGetProcessMonitorStatusQuery()

  const statusChartData = useMemo(() => {
    if (!data || data.length === 0) {
      return {
        titleValue: 0,
        descriptionValue: '0 hours',
        data: {
          labels: ['No process work done in the last 7 days'],
          datasets: [{ data: [1], backgroundColor: CHART_NEUTRAL_HSLA }],
        },
      }
    }

    const secToHourMultiplier = 3600 // 60 * 60

    return {
      titleValue: data.length,
      descriptionValue: `${Math.round(
        data.reduce((acc: number, item) => acc + item.total_duration_sec, 0) / secToHourMultiplier,
      )} hours`,
      data: {
        labels: [...data.map((item) => item.process_name)],
        datasets: [
          {
            data: [
              ...data.map((item) => Math.round(item.total_duration_sec / secToHourMultiplier)),
            ],
            backgroundColor: CHART_PRIMARY_HSLA,
          },
        ],
      },
    }
  }, [data])

  return (
    <StatusCard
      chartData={statusChartData.data}
      titleProps={{ label: 'Processes tracked', value: statusChartData.titleValue }}
      descriptionProps={{ label: 'Work from last 7 days', value: statusChartData.descriptionValue }}
      navProps={{
        label: 'Configure new processes',
        link: AdminMainPathEnum.PROCESS,
      }}
      isLoading={isLoading}
    />
  )
}

export default ProcessStatus
