import clsx from 'clsx'

import { Divider, Text } from '../../../../common/designs'
import { formatNumber } from '../../../../common/utils/numberFormatting'
import { AutomationInsightNameEnum } from './types'
import { useNodeHighlightData } from './useNodeHighlightData'

interface Props {
  stepName: string
}

const infoTextMapper = {
  [AutomationInsightNameEnum.IDP]:
    'Of the active work time has potential to be enhanced with Intelligent Document Processing',
  [AutomationInsightNameEnum.TEMPLATIZATION]:
    'Of the active work time has potential to be eliminated with Templatization',
  [AutomationInsightNameEnum.RPA]: 'Of the active work time has potential to be automated with RPA',
  [AutomationInsightNameEnum.GEN_AI]:
    'Of the active work time has potential to be enhanced with Generative AI',
}

const HIGH_THRESHOLD = 40
const MIN_THRESHOLD = 15

const NodeAutomationInsights = ({ stepName }: Props) => {
  const { automationInsightsData, isAutomationInsightsOpen } = useNodeHighlightData()

  if (!isAutomationInsightsOpen || !automationInsightsData || !automationInsightsData[stepName])
    return null

  const shownInsights = [...automationInsightsData[stepName]]
    .filter((i) => i.duration_percentage > MIN_THRESHOLD)
    .sort((a, b) => b.duration_percentage - a.duration_percentage)

  return (
    <>
      <Divider label='Automation Insights' color='darkGray' className='my-3' />
      <div className='space-y-2'>
        {shownInsights.map(({ duration_percentage, insight_name }) => {
          return (
            <div
              key={insight_name}
              className={clsx(
                'rounded-lg border-4 px-2 py-1',
                duration_percentage > HIGH_THRESHOLD
                  ? 'border-red-600 bg-red-200'
                  : 'border-orange-600 bg-orange-200',
              )}
            >
              <Text
                className={
                  duration_percentage > HIGH_THRESHOLD ? 'text-red-600' : 'text-orange-600'
                }
                weight={500}
              >
                {duration_percentage > HIGH_THRESHOLD ? 'High' : 'Medium'} {insight_name} potential
              </Text>
              <Text
                className={
                  duration_percentage > HIGH_THRESHOLD ? 'text-red-600' : 'text-orange-600'
                }
                weight={600}
              >
                {formatNumber(Math.min(95, duration_percentage))} %
              </Text>
              <Text size='s' weight={500} color='darkGray'>
                {infoTextMapper[insight_name]}
              </Text>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default NodeAutomationInsights
